import ReactGA from 'react-ga4';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';

export interface AnalyticsData {
  subject: any;
  object?: any;
  session?: any;
}

const recordAnalyticsEvent = (eventName: AnalyticsEvent, additionalData: AnalyticsData) => {
  console.log('Event: ', eventName, additionalData);
  ReactGA.event(eventName, additionalData);

  const analyticsData = {
    ...additionalData,
    event: eventName,
    platform: 'MY_MNI',
    date: new Date().toISOString(),
  };

  EVENT_QUEUE.push(analyticsData);
};

const EVENT_QUEUE: any[] = [];
const API_ENDPOINT = 'https://analytics.marketnews.com/streams/user-events/records';
const FLUSH_INTERVAL = 10000; // 10 seconds

// Function to send events to the API
const flushEvents = async () => {
  if (EVENT_QUEUE.length === 0) return;

  const eventsToSend = [...EVENT_QUEUE];
  try {
    EVENT_QUEUE.length = 0; // Clear the queue before sending

    if (process.env.REACT_APP_TEST_ENV !== 'true') {
      await fetch(API_ENDPOINT, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(eventsToSend),
      });

      console.log(`✅ Sent ${eventsToSend.length} analytics events`);
    }
  } catch (error) {
    console.error('❌ Error sending analytics events:', error);
    // Re-add events to the queue in case of failure
    EVENT_QUEUE.unshift(...eventsToSend);
  }
};

// Flush events every 10 seconds
setInterval(flushEvents, FLUSH_INTERVAL);

// Ensure events are sent before closing the tab
const handleBeforeUnload = () => {
  if (process.env.REACT_APP_TEST_ENV !== 'true' && EVENT_QUEUE.length > 0) {
    fetch(API_ENDPOINT, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(EVENT_QUEUE),
      keepalive: true,
    });
  }
};

window.addEventListener('beforeunload', handleBeforeUnload);
window.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'hidden') handleBeforeUnload();
});

const analyticsService = {
  recordAnalyticsEvent,
};

export default analyticsService;
