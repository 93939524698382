import React, { useContext, useEffect, useState, useRef } from 'react';
import { Accordion } from 'react-bootstrap';

import { SearchQueryFilterType } from '@/model/search/search';
import useIsMobile from '@/hooks/use-is-mobile';
import { useClickOutside } from '@/hooks/use-on-click-outside';
import {
  searchFiltersTypes,
  SearchContext,
  SearchFilterType,
} from '@/components/dashboard/search/search-context';
import '@/components/dashboard/search/search-results.scss';
import SearchTypeIcon from '@/components/dashboard/search/search-type-icon';
import SearchResultsList from '@/components/dashboard/search/search-results-list';
import ArticleModal from '@/components/articles/article-modal';

export default function SearchResults() {
  const resultsRef = useRef<HTMLDivElement>(null);
  const {
    results,
    filters,
    setFilters,
    searchResults,
    setPagination,
    search,
    searchRef,
    modalRef,
    hideSearchResults,
    setHideSearchResults,
    selectedArticle,
    setSelectedArticle,
  } = useContext(SearchContext)!;
  const [searchFiltersTypesActive, setSearchFiltersTypesActive] =
    useState<SearchFilterType[]>(searchFiltersTypes);
  const [expandedType, setExpandedType] = useState<SearchFilterType | null>(null);

  const isMobile = useIsMobile(991);

  useClickOutside(resultsRef, () => setHideSearchResults(true), [searchRef, modalRef]);

  const toggleTypeFilter = (type: SearchFilterType) => {
    let newTypes = [...(filters.types || [])];

    // article is a special case, if all article types are active, deactivate them all
    if (type === 'article') {
      const articleTypes: SearchQueryFilterType[] = ['story', 'newsletter', 'headline'];
      const hasAllArticleTypes = articleTypes.every(t => newTypes.includes(t));

      if (hasAllArticleTypes) {
        newTypes = newTypes.filter(t => !articleTypes.includes(t));
      } else {
        newTypes = [...newTypes.filter(t => !articleTypes.includes(t)), ...articleTypes];
      }
    } else if (newTypes.includes(type)) {
      newTypes = newTypes.filter(t => t !== type);
    } else {
      newTypes.push(type);
    }
    // reset expanded type when changing filters
    setExpandedType(null);
    setSearchFiltersTypesActive(prev => {
      if (prev.includes(type)) {
        return prev.filter(t => t !== type);
      }
      return [...prev, type];
    });
    setFilters({ ...filters, types: newTypes });
    searchResults();
  };

  useEffect(() => {
    setHideSearchResults(false);
    if (search === '') {
      setHideSearchResults(true);
      setSearchFiltersTypesActive(searchFiltersTypes);
    }
  }, [search, setHideSearchResults]);

  useEffect(() => {
    const totalResultsToShow = 12;
    const activeFiltersCount = searchFiltersTypesActive.length;
    const linesToDisplay = Math.ceil(totalResultsToShow / activeFiltersCount);
    setPagination({ page: 1, pageSize: linesToDisplay });
  }, [setPagination, searchFiltersTypesActive]);

  useEffect(() => {
    const articleTypes: SearchQueryFilterType[] = ['story', 'newsletter', 'headline'];
    if (expandedType !== null) {
      setPagination({ page: 1, pageSize: 25 });
      if (expandedType === 'article') {
        setFilters({ ...filters, types: articleTypes });
      } else setFilters({ ...filters, types: [expandedType] });
    } else {
      let newTypes: SearchQueryFilterType[] = [];
      searchFiltersTypesActive.forEach(f => {
        if (f !== 'article') newTypes.push(f);
        else newTypes = newTypes.concat(articleTypes);
      });
      setFilters({ ...filters, types: newTypes });
    }
    // eslint-disable-next-line
  }, [expandedType]);

  const Filters = () => (
    <div className="w-25 d-flex justify-content-center p-3 border-end">
      <Accordion defaultActiveKey={['0']} alwaysOpen style={{ width: '180px' }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h3 className="text-text-dark">Type</h3>
          </Accordion.Header>
          <Accordion.Body className="d-flex flex-column gap-2">
            {searchFiltersTypes.map(type => (
              <div key={type} className="d-flex align-items-center gap-2">
                <input
                  type="checkbox"
                  checked={searchFiltersTypesActive?.includes(type)}
                  onChange={() => toggleTypeFilter(type)}
                  className="custom-checkbox flex-shrink-0"
                />
                <SearchTypeIcon type={type} />
                <span className="fs-5">
                  {type === 'genre' ? 'Article serie' : type[0].toUpperCase() + type.slice(1)}s
                </span>
              </div>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );

  const Results = () => {
    // filter results based on active filters
    const filterResults = (type: SearchFilterType) => {
      const articleTypes: SearchQueryFilterType[] = ['story', 'newsletter', 'headline'];
      if (type === 'article') {
        return results.filter(r => r.types.some(t => articleTypes.includes(t)));
      }
      return results.filter(
        r => r.types.includes(type) && !r.types.some(t => articleTypes.includes(t)),
      );
    };
    // amount of lines to display for each type depending on the amount of active filters
    const totalResultsToShow = 12;
    const activeFiltersCount = searchFiltersTypesActive.length;
    const linesToDisplay = Math.ceil(totalResultsToShow / activeFiltersCount);

    return (
      <ul
        className="w-100 search-results-list list-group p-3"
        style={isMobile ? { maxHeight: '40vh', overflow: 'auto' } : { maxHeight: '75vh' }}
      >
        {searchFiltersTypesActive.map(type => {
          const typeResults = filterResults(type);
          if (!typeResults.length) return <React.Fragment key={type}></React.Fragment>;
          return (
            <React.Fragment key={type}>
              {(expandedType === null || expandedType === type) && (
                <li className="mb-3 list-group-item border-0 bg-primary-700 h-100 py-0">
                  <SearchResultsList
                    type={type}
                    results={typeResults}
                    expand={expandedType === type}
                    setExpandedType={setExpandedType}
                    linesToDisplay={linesToDisplay}
                  />
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      {!search.length || hideSearchResults ? (
        <></>
      ) : !results.length ? (
        <div className="position-fixed w-100 hv-25 z-3 bg-primary-700 p-3" style={{ top: '56px' }}>
          <h3>No search results</h3>
        </div>
      ) : (
        <div
          ref={resultsRef}
          className="position-fixed search-results w-100 z-3 bg-primary-700 d-block d-lg-flex"
          style={{ maxHeight: '75vh', top: '56px' }}
        >
          <Filters />
          <div
            className="d-block d-lg-none start-0 w-100 bg-bg-300 my-3"
            style={{ height: '1px', top: '25%' }}
          ></div>
          <Results />
        </div>
      )}
      <ArticleModal onClose={() => setSelectedArticle(undefined)} articleId={selectedArticle} />
    </>
  );
}
