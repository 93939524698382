import DashboardWidgetPanel from '@/components/dashboard/dashboard-widget-panel';
import { Context } from '@/App';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { ActiveWidgetsForDashboard } from '@/components/dashboard/hooks/use-widgets-from-layouts';
import {
  breakpoints,
  cols,
  getBreakpointFromElement,
} from '@/components/dashboard/dashboard-breakpoints';
import {
  Breakpoint,
  DashboardAction,
} from '@/components/dashboard/store/dashboard-content-reducer';
import EmptyStateIndicator from '@/components/shared/empty-state-indicator';
import DashboardAddItemControl from '@/components/dashboard/dashboard-add-item-control';
import { AppContext } from '@/model/app-context';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import useSessionId from '../auth/session-provider';

export default function DashboardContent({
  layouts,
  editMode,
  dispatch,
  activeWidgets,
  highlightedWidgets,
}: {
  layouts: Layouts;
  editMode: boolean;
  activeWidgets: ActiveWidgetsForDashboard;
  dispatch: (a: DashboardAction) => void;
  highlightedWidgets: { [k: string]: boolean };
}) {
  const [currentBreakpoint, setCurrentBreakPoint] = useState<Breakpoint>();
  const ref = useRef<HTMLDivElement | null>(null);
  const [bottomLinkVisible, setBottomLinkVisible] = useState<boolean>(false);
  const bottomAnchorRef = useRef<HTMLDivElement | null>(null);
  const [topLinkVisible, setTopLinkVisible] = useState<boolean>(false);
  const topAnchorRef = useRef<HTMLDivElement | null>(null);

  const [internalLayouts, setInternalLayouts] = useState<Layouts>();
  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const context = useContext<AppContext>(Context);
  const [scrolling, setScrolling] = useState<boolean>(false);

  const sessionId = useSessionId() ?? '';

  useEffect(() => {
    if (currentBreakpoint) dispatch({ type: 'setBreakpoint', breakpoint: currentBreakpoint });
    // eslint-disable-next-line
  }, [currentBreakpoint]);

  useEffect(() => {
    setInternalLayouts({ ...filterUniqueLayouts(layouts) });
  }, [layouts]);

  const filterUniqueLayouts = (layouts: Layouts) => {
    const filteredLayouts: Layouts = {};

    Object.keys(layouts).forEach(breakpoint => {
      const seen = new Set();
      filteredLayouts[breakpoint] = layouts[breakpoint].filter(item => {
        if (!seen.has(item.i)) {
          seen.add(item.i);
          return true;
        }
        return false;
      });
    });

    return filteredLayouts;
  };

  const getLayoutForWidget = (id: string): { x: number; y: number; w: number; h: number } => {
    if (internalLayouts) {
      for (let internalLayoutsKey in internalLayouts) {
        const saved = internalLayouts[internalLayoutsKey].find(value => value.i === id);

        if (saved) {
          return saved;
        }
      }
    }

    return { x: 0, y: -1, w: 4, h: 4 };
  };

  function _preventTextSelect(a: any, b: any, c: any, d: any, event: MouseEvent) {
    event.preventDefault();
  }

  useEffect(() => {
    if (ref.current) {
      setCurrentBreakPoint(getBreakpointFromElement(ref.current));

      const node = ref.current as HTMLDivElement;

      const resizeObserver = new ResizeObserver(() => {
        // Do what you want to do when the size of the element changes

        const h1 = ref.current?.parentElement?.clientHeight || 0;
        const h2 = node.clientHeight;

        if (h2 > h1) setScrolling(true);
        else setScrolling(false);
      });
      resizeObserver.observe(node);
    }
    // eslint-disable-next-line
  }, [ref, ref.current]);

  useEffect(() => {
    if (bottomAnchorRef.current) {
      const node = bottomAnchorRef.current as HTMLDivElement;
      new IntersectionObserver(([entries]) => {
        setBottomLinkVisible(!entries.isIntersecting);
      }).observe(node);
    }
    // eslint-disable-next-line
  }, [bottomAnchorRef.current]);

  useEffect(() => {
    if (topAnchorRef.current) {
      const node = topAnchorRef.current as HTMLDivElement;
      new IntersectionObserver(([entries]) => {
        setTopLinkVisible(!entries.isIntersecting);
      }).observe(node);
    }
    // eslint-disable-next-line
  }, [topAnchorRef.current]);

  const onLayoutChange = (_currentLayout: Layout[], allLayouts: Layouts) => {
    const breakpoint = Object.keys(allLayouts).find(value => allLayouts[value] === _currentLayout);

    if (breakpoint !== currentBreakpoint) {
      console.debug('Change is for another breakpoint', breakpoint, currentBreakpoint);
    }

    dispatch({
      type: 'setLayouts',
      layout: allLayouts,
      breakpoint: breakpoint as Breakpoint,
    });
  };

  const widgetPanels = useMemo(
    () =>
      activeWidgets?.activeWidgets?.map(value => {
        if (!currentBreakpoint || !activeWidgets || !internalLayouts) {
          return <span key={value.id}></span>;
        }
        const layoutForWidget = getLayoutForWidget(value.id);

        return (
          <div
            key={value.id}
            data-grid={layoutForWidget}
            className={'overflow-hidden responsive-layout'}
          >
            <DashboardWidgetPanel
              highlight={highlightedWidgets[value.id]}
              widget={value}
              editMode={editMode}
              initialState={value.initialState ? value.initialState() : undefined}
              onClose={(save: boolean = false) => {
                dispatch({ type: 'removeWidget', id: value.id, sessionId });

                if (save) dispatch({ type: 'saveCurrentDashboard', sessionId });
              }}
              onHeaderDoubleClick={() => {
                dispatch({ type: 'editCurrentDashboard' });
              }}
              onSave={() => {
                dispatch({ type: 'saveCurrentDashboard', sessionId });
              }}
            />
          </div>
        );
      }),
    // eslint-disable-next-line
    [activeWidgets, editMode, currentBreakpoint, highlightedWidgets, internalLayouts],
  );

  const breakpointChange = (newBreakpoint: string) => {
    setCurrentBreakPoint(newBreakpoint as Breakpoint);
  };

  return (
    <Context.Provider value={{ showMenu: false, localPreferences: context.localPreferences }}>
      {scrolling && topLinkVisible && (
        <div className={'position-absolute top-0 z-3 mt-5 vw-100 text-center'}>
          <Button
            size={'sm'}
            className={'border border-2 rounded-3'}
            variant={'primary'}
            onClick={event => topAnchorRef.current?.scrollIntoView({ behavior: 'smooth' })}
          >
            <FontAwesomeIcon icon={faCaretUp} />
          </Button>
        </div>
      )}
      {scrolling && bottomLinkVisible && (
        <div className={'position-absolute z-3  vw-100 text-center overflow-hidden bottom-0'}>
          <div style={{ height: '1.7em' }}>
            <Button
              className={'border border-2 rounded-3'}
              size={'sm'}
              variant={'primary'}
              onClick={event => bottomAnchorRef.current?.scrollIntoView({ behavior: 'smooth' })}
            >
              <FontAwesomeIcon icon={faCaretDown} />
            </Button>
          </div>
        </div>
      )}

      <div className={'h-0'} ref={topAnchorRef}></div>
      <div className={'w-100 '} ref={ref} key={'div-cont'}>
        {internalLayouts && currentBreakpoint && activeWidgets && (
          <ResponsiveReactGridLayout
            key={'grid'}
            draggableHandle={'.drag-handle'}
            onLayoutChange={onLayoutChange}
            onBreakpointChange={breakpointChange}
            onDrag={_preventTextSelect}
            onResize={_preventTextSelect}
            onResizeStop={_preventTextSelect}
            onDragStart={_preventTextSelect}
            onDragStop={_preventTextSelect}
            className="layout"
            isDraggable={editMode}
            isDroppable={editMode}
            isResizable={editMode}
            cols={cols}
            rowHeight={100}
            layouts={internalLayouts}
            margin={[10, 10]}
            useCSSTransforms={true}
            breakpoints={breakpoints}
            resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
            containerPadding={[5, 5]}
          >
            {widgetPanels}
          </ResponsiveReactGridLayout>
        )}
        {/*  eslint-disable-next-line  */}
        {activeWidgets?.activeWidgets.length == 0 && (
          <div className={'d-flex w-100 flex-column justify-content-around align-items-center p-5'}>
            <div style={{ width: '10em' }}>
              <EmptyStateIndicator
                text={
                  <>
                    No Items
                    <div>
                      <DashboardAddItemControl
                        onAddWidget={w =>
                          dispatch({
                            type: 'addWidget',
                            id: w,
                            sessionId,
                          })
                        }
                        activeWidgets={activeWidgets.activeWidgets}
                      />
                    </div>
                  </>
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className={'h-0'} ref={bottomAnchorRef}></div>
    </Context.Provider>
  );
}
