import { useEffect, useRef, useState } from 'react';
import { addClickHandlersToElement } from '@/components/shared/pdf-viewer';
import styles from '@/components/articles/article-view.module.scss';
import useSubscription from '@/hooks/use-subscription';
import { analyticsService, authService, messageService } from '@/services';
import { Article } from '@/model/article';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import useSessionId from '../auth/session-provider';

function addTableResponsiveDiv(table: HTMLTableElement) {
  const div = document.createElement('div');

  div.className = 'table-responsive';

  table.parentElement?.replaceChild(div, table);
  div.appendChild(table);

  let classes = table.getAttribute('class') || '';

  if (!classes.includes('table')) classes = `${classes} table`;

  table.setAttribute('class', classes);
}

export default function ArticleBodyDisplay({
  article,
  xhtml,
  pinnedWidget,
}: {
  article: Article;
  xhtml: string;
  pinnedWidget?: boolean;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [toggle, setToggle] = useState<boolean>(true);

  const sessionId = useSessionId();
  const subject = authService.getSubjectData();

  useEffect(() => {
    const anchorListeners: { element: HTMLAnchorElement; handler: EventListener }[] = [];

    if (ref && xhtml) {
      if (!ref.current) return;

      addClickHandlersToElement(ref.current, () => {
        messageService.publish('article-model', {});
      });

      // custom scrolling behaviour on pinned articles (series as well)
      // add more when needed (probably different query selector)
      const widgetContainer = pinnedWidget
        ? (document.querySelector('.container-responsive-text') as HTMLElement | null)
        : null;
      const anchors = ref.current?.getElementsByTagName<'a'>('a');

      if (anchors) {
        for (const a of anchors) {
          const link = a.getAttribute('href');

          if (!link || !link.startsWith('#')) {
            a.setAttribute('target', '_blank');
          } else {
            if (widgetContainer) {
              const handler = (event: Event) => {
                event.preventDefault();
                event.stopPropagation();

                const targetId = link.substring(1);
                const targetElement = ref.current?.querySelector(
                  `[id="${targetId}"]`,
                ) as HTMLElement | null;

                if (targetElement && widgetContainer) {
                  widgetContainer.scrollTo({
                    top: targetElement.offsetTop - widgetContainer.offsetTop,
                    behavior: 'smooth',
                  });
                }
              };
              a.addEventListener('click', handler);
              anchorListeners.push({ element: a, handler });
            }
          }
        }
      }

      const tables = ref.current?.getElementsByTagName<'table'>('table');

      if (tables) {
        for (const a of tables) {
          try {
            addTableResponsiveDiv(a as HTMLTableElement);
          } catch (e) {
            console.error('Could not fix table', e);
          }
        }
      }

      if (anchors) {
        for (const a of anchors) {
          if (
            a.href &&
            a.href.toLowerCase().endsWith('.pdf') &&
            a.href.toLowerCase().startsWith('https://media.marketnews.com')
          ) {
            a.className = ' btn btn-tertiary ' + styles.downloadPdfButton;
            a.innerHTML = a.innerText;

            const handler = (event: Event) => {
              analyticsService.recordAnalyticsEvent(AnalyticsEvent.ARTICLE_PDF_VIEWED, {
                subject,
                object: {
                  type: AnalyticsTypes.ARTICLE,
                  id: article.uri,
                  title: article.headline,
                  genres: article.genre.map(g => ({ id: `${g.scheme}:${g.code}`, name: g.name })),
                  subjects: article.subject.map(s => ({
                    id: `${s.scheme}:${s.code}`,
                    name: s.name,
                  })),
                  authors: article.byline,
                },
                session: {
                  id: sessionId,
                },
              });
            };

            a.addEventListener('click', handler);

            anchorListeners.push({ element: a, handler });
          }
        }
      }
    }

    // Cleanup
    return () => {
      for (const { element, handler } of anchorListeners) {
        element.removeEventListener('click', handler);
      }
    };
    // eslint-disable-next-line
  }, [ref.current, xhtml, toggle]);

  useSubscription('article-model', message => {
    setToggle(false);
  });

  useEffect(() => {
    setToggle(true);
  }, [toggle]);

  return (
    <>
      {' '}
      <div ref={ref} dangerouslySetInnerHTML={{ __html: xhtml }} className={styles.article}></div>
      {toggle && <span />}
    </>
  );
}
