import { Button, Modal } from 'react-bootstrap';

export default function DashboardAddWidgetModal({
  showAddWidgetModal,

  onResolve,
}: {
  showAddWidgetModal: boolean;

  onResolve: (res: boolean) => void;
}) {
  return (
    <Modal
      show={showAddWidgetModal}
      onHide={() => {
        onResolve(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="p-3">
        <h4 className="text-center">Add widget</h4>
        <div className="text-center my-3">
          <p>Do you want to add this widget to your dashboard?</p>
        </div>
        <Modal.Footer>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-warning"
            onClick={() => {
              onResolve(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ height: '40px', fontFamily: 'Inter' }}
            className="btn btn-success"
            onClick={() => {
              onResolve(true);
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}
