import { BulletListWidget } from '@/widgets/widgets/scrollers/bullet-list-widget';
import { DataCalendarWidget } from '@/widgets/widgets/calendars/data-calendar-widget';
import { SettingsMenuWidget } from '@/widgets/widgets/settings-menu-widget';
import { SearchWidget } from '@/widgets/widgets/search-widget';
import { MainwireScrollerWidget } from '@/widgets/widgets/scrollers/mainwire-scroller-widget';
import { NewsWidget } from '@/widgets/news-widget';
import { SingleCalendarWidgetFactory } from '@/widgets/widgets/calendars/single-calendar-widget';
import { NewsWidgetFactory } from '@/widgets/news-widget-factory';
import { PinnedArticleWidgetFactory } from '@/widgets/widgets/pinned/pinned-article-widget-factory';
import { PinnedSeriesWidgetFactory } from '@/widgets/widgets/pinned/pinned-series-widget-factory';
import { ChatWidget } from '@/widgets/widgets/chat-widget';
import { ClockWidget } from '@/widgets/widgets/clock/clock-widget';
import { PodcastWidgetFactory } from '@/widgets/widgets/podcasts/podcast-widget';

const widgets = [
  new BulletListWidget(
    'all-bullets',
    [
      'fx-bullets',
      'fi-bullets',
      'em-bullets',
      'eu-credit-bullets',
      'em-credit-bullets',
      'oil-bullets',
      'power-bot',
      'gas-bullets',
      'us-oil',
      'us-gas-bullets',
    ],
    {
      title: 'All Bullets',
      titleSubstitutions: [{ codes: ['us-oil', 'us-gas-bullets'], name: 'US O&G' }],
    },
    'All',
  ),
  new BulletListWidget('em-bullets', ['em-bullets'], { title: 'EM Bullets' }, 'FI'),
  new BulletListWidget('dm-bullets', ['fx-bullets', 'fi-bullets'], { title: 'DM Bullets' }, 'FX'),
  new BulletListWidget(
    'gm-bullets',
    ['dm-gm-bullets', 'em-gm-bullets'],
    { title: 'Global Macro Bullets' },
    'GM',
  ),
  new BulletListWidget(
    'credit-bullets',
    ['eu-credit-bullets', 'em-credit-bullets'],
    { title: 'Credit Bullets' },
    'CR',
  ),
  new BulletListWidget(
    'energy-bullets',
    ['oil-bullets', 'power-bot', 'gas-bullets', 'us-oil', 'us-gas-bullets'],
    {
      title: 'Energy Bullets',
      titleSubstitutions: [{ codes: ['us-oil', 'us-gas-bullets'], name: 'US O&G' }],
    },
    'Oil',
  ),
  new MainwireScrollerWidget(
    'mainwire',
    ['mainwire', 'em-mainwire'],
    { title: 'Policy Mainwire' },
    'Mainwire',
  ),

  new DataCalendarWidget(),
  new SearchWidget(),
  //new BookmarksWidget(),

  //new HotListWidget(),
  new SettingsMenuWidget(),
  new ChatWidget(),
  new ClockWidget(),
];

const templates = [
  new SingleCalendarWidgetFactory(),
  new PinnedArticleWidgetFactory(),
  new PinnedSeriesWidgetFactory(),
  new PodcastWidgetFactory(),
];

export interface WidgetFactory {
  getWidgetById(id: string): NewsWidget | undefined;

  getStaticWidgetList(): NewsWidget[];

  getDynamicWidgetFactories(): NewsWidgetFactory[];
}

export default function useWidgetFactory(): WidgetFactory {
  return {
    getWidgetById(id: string) {
      let template = templates.find(value => value.matchesTemplate(id));

      if (template) return template.createInstance(id);
      // eslint-disable-next-line
      return widgets.find(value => value.id == id);
    },

    getStaticWidgetList(): NewsWidget[] {
      return widgets;
    },
    getDynamicWidgetFactories(): NewsWidgetFactory[] {
      return templates;
    },
  };
}
