import { Article } from '@/model/article';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as bookmarkSolid } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as bookmarkOutline } from '@fortawesome/free-regular-svg-icons';
import { opinionsApi } from '@/api';
import { useEffect, useState } from 'react';
import { analyticsService, authService, messageService } from '@/services';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { OpinionMap } from '@/model/opinions/opinion-map';
import useSubscription from '@/hooks/use-subscription';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import useSessionId from '../auth/session-provider';

export default function BookmarkButton({
  article,
  providedOpinions,
  onToggle,
}: {
  article: Article;
  providedOpinions?: OpinionMap;
  onToggle?: (bookmark: boolean) => void;
}) {
  const [opinions, setOpinions] = useState<OpinionMap>(providedOpinions || {});
  const sessionId = useSessionId();
  const subject = authService.getSubjectData();
  const bookmark = async () => {
    if (isBookmarked()) {
      analyticsService.recordAnalyticsEvent(AnalyticsEvent.ARTICLE_UN_BOOKMARKED, {
        subject,
        object: {
          type: AnalyticsTypes.ARTICLE,
          id: article.uri,
          title: article.headline,
          genres: article.genre.map(g => ({ id: `${g.scheme}:${g.code}`, name: g.name })),
          subjects: article.subject.map(s => ({ id: `${s.scheme}:${s.code}`, name: s.name })),
          authors: article?.byline,
        },
        session: {
          id: sessionId,
        },
      });
      onToggle && onToggle(false);
      await opinionsApi.removeOpinion(article.uri, 'BOOKMARK');
    } else {
      analyticsService.recordAnalyticsEvent(AnalyticsEvent.ARTICLE_BOOKMARKED, {
        subject,
        object: {
          type: AnalyticsTypes.ARTICLE,
          id: article.uri,
          title: article.headline,
          genres: article.genre.map(g => ({ id: `${g.scheme}:${g.code}`, name: g.name })),
          subjects: article.subject.map(s => ({ id: `${s.scheme}:${s.code}`, name: s.name })),
          authors: article?.byline,
        },
        session: {
          id: sessionId,
        },
      });
      onToggle && onToggle(true);
      await opinionsApi.saveOpinion(article.uri, 'BOOKMARK');
    }

    opinionsApi.getOpinionsOfArticles(article.uri).then(value => {
      setOpinions(value);
      messageService.publish(MessageServiceConstants.BOOKMARKS_CHANGED, value);
    });
  };

  useSubscription<OpinionMap>(MessageServiceConstants.BOOKMARKS_CHANGED, message => {
    setOpinions({ ...opinions, ...message });
  });

  const isBookmarked = () => {
    return opinions[article.uri]?.includes('BOOKMARK');
  };

  useEffect(() => {
    if (!providedOpinions) opinionsApi.getOpinionsOfArticles(article.uri).then(setOpinions);
    // eslint-disable-next-line
  }, [article]);

  useEffect(() => {
    if (providedOpinions) setOpinions(providedOpinions);
  }, [providedOpinions]);

  return (
    <button
      onClick={bookmark}
      style={{ fontFamily: 'Inter' }}
      className={`btn btn-sm btn-link link-warning pt-0 pb-0`}
    >
      {isBookmarked() && <FontAwesomeIcon icon={bookmarkSolid} />}
      {!isBookmarked() && <FontAwesomeIcon icon={bookmarkOutline} />}
    </button>
  );
}
