import { Token } from '@/model/auth/token';
import { createCredentials, Credentials, CredentialsData } from '@/model/auth/credentials';
import { CredentialsBody } from '@/model/auth/credentials-body';
import { LocalStorageUtil } from '@/services/util/local-storage-util';
import { PromiseQueue } from '@/services/util/promise-queue';

const localStorage = new LocalStorageUtil();
let tokenObservers: Function[] = [];

const TOKEN_REFRESH_INTERVAL = 3.6e6;
const TOKEN_STORAGE_KEY = 'access_token';

const refreshQueue = new PromiseQueue<Credentials | undefined>();

console.log('Starting refresh timer');
setInterval(() => {
  refreshToken().then(value => {
    console.log('Refreshed token');
  });
}, TOKEN_REFRESH_INTERVAL);

function setCredentials(credentials: CredentialsData | undefined) {
  if (!credentials) localStorage.removeValue(TOKEN_STORAGE_KEY);
  else localStorage.saveValue(TOKEN_STORAGE_KEY, credentials);
}

function getCredentials(): Credentials | undefined {
  const value = localStorage.getValue<CredentialsData>(TOKEN_STORAGE_KEY);

  if (value?.body && value.header && value.token) return createCredentials(value);

  return undefined;
}

async function loginWithAuth0(
  getAccessTokenSilently: () => Promise<string>,
  setCookie: (name: string, value: any, config: any) => void,
): Promise<Token> {
  if (!tokenValid()) {
    console.log('Refreshing auth0 server token');

    const value = await getAccessTokenSilently();

    const { body } = unmarshallJWTToken(value);

    setCookie('permissions', body.permissions?.join(',') ?? '', {
      httpOnly: false,
      path: '/',
      maxAge: 604800, // 60 * 60 * 24 * 7, a week
      secure: !(process.env.REACT_APP_TEST_ENV === 'true'), //could be undefined for prod
      domain: process.env.REACT_APP_TEST_ENV === 'true' ? undefined : '.mnimarkets.com',
    });

    await storeToken({
      source: 'auth0',
      access_token: value,
      expires_in: body.exp - new Date().getTime() / 1000,
    } as Token);
  }

  return getCredentials()?.token as Token;
}

async function loginWithToken(t: string): Promise<Token> {
  if (!tokenValid()) {
    console.log('Refreshing server token');

    //'https://apis.marketnews.com/api/auth/exchange/token'

    let response = await fetch('https://apis.marketnews.com/api/auth/rm-exchange/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ access_token: t }),
    });

    if (response.status !== 200) throw new Error('Login Failed');

    const token = await response.json();
    token.source = 'remote-token';

    if (token) {
      await storeToken(token);
    }
  }

  return getCredentials()?.token as Token;
}

async function loginWithUserAndPassword(username: string, password: string): Promise<Token> {
  if (!tokenValid()) {
    console.log('Refreshing server token');

    let response = await fetch('https://api.alphaflash.com/api/auth/alphaflash-client/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.status !== 200) throw new Error('Login Failed');

    const token = await response.json();
    token.source = 'username-password';

    if (token) {
      await storeToken(token);
    }
  }

  return getCredentials()?.token as Token;
}

async function refreshToken(): Promise<Credentials | undefined> {
  return refreshQueue.enqueue(async () => {
    const storedCredentials = getCredentials();
    const refresh_token = storedCredentials?.token.refresh_token;

    if (refresh_token) {
      let response = await fetch('https://api.alphaflash.com/api/auth/refresh', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ refresh_token }),
      });

      if (response.status !== 200) throw new Error('Login Failed');

      const token = await response.json();
      token.source = storedCredentials?.token.source;

      if (token) {
        return storeToken(token);
      }
    }
  });
}

function unmarshallJWTToken(token: string) {
  const [headerString, bodyString] = token.split('.');

  return {
    header: JSON.parse(atob(headerString)),
    body: JSON.parse(atob(bodyString)),
  };
}

function getCredentialsFromToken(token: Token): CredentialsData {
  const [headerString, bodyString] = token.access_token.split('.');

  const body: CredentialsBody = JSON.parse(atob(bodyString));

  if (!body.permissions && body.roles) body.permissions = body.roles;

  return {
    header: JSON.parse(atob(headerString)),
    refresh_expiration: new Date(new Date().getTime() + (token.refresh_expires_in || 0) * 1000),
    access_expiration: new Date(new Date().getTime() + (token.expires_in || 0) * 1000),
    body,
    token,
  };
}

function storeToken(token: Token) {
  setCredentials(getCredentialsFromToken(token));

  notifyTokenAvailable(token);

  return getCredentials();
}

async function logout() {
  setCredentials(undefined);
}

async function onTokenAvailable(): Promise<Token> {
  let stored = await getAccessToken();

  if (stored) return stored;
  else
    return new Promise<Token>(resolve => {
      tokenObservers.push(resolve);
    });
}

function notifyTokenAvailable(token: Token) {
  tokenObservers.forEach(value => value(token));
  tokenObservers = [];
}

async function getAccessToken(): Promise<Token | undefined> {
  let stored = getCredentials();

  if (!stored) return;

  if (!stored.accessTokenExpired()) {
    return stored.token;
  } else if (!stored.refreshTokenExpired()) {
    return (await refreshToken())?.token;
  }
}

function tokenValid() {
  const storedCredentials = getCredentials();
  if (!storedCredentials) return false;

  return !storedCredentials?.accessTokenExpired();
}

function getSubjectData() {
  const storedCredentials = getCredentials();
  if (!storedCredentials) return null;

  const sub = storedCredentials.body.sub;

  if (sub.startsWith('auth0|')) {
    return {
      id: sub,
      provider: 'auth0',
    };
  }

  const realm = storedCredentials.body.realm;

  return {
    id: sub,
    provider: `mni|${realm}`,
  };
}

// eslint-disable-next-line
export default {
  onTokenAvailable,
  getAccessToken,
  logout,
  loginWithToken,
  loginWithAuth0,
  getCredentials,
  loginWithUserAndPassword,
  tokenValid,
  getSubjectData,
};
