import { Layout, Layouts } from 'react-grid-layout';
import { MNIDashboard, MNIDashboardWidgetPreferences } from '@/model/preferences/myMNIPreferences';
import { Reducer } from 'react';
import { analyticsService, authService } from '@/services';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';

export type Breakpoint = 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
export type DashboardState = {
  /**
   * if the dashboard is loading
   */
  loading: boolean;
  /**
   * If the dashboard is in an edit state
   */
  editing: boolean;
  /**
   * If the model has changes that need to be saved
   */
  dirty: boolean;
  saveWhileEditing?: boolean;
  /**
   * The connically saved dashboard version.
   */
  dashboards: MNIDashboard[];
  /**
   * the ID of the currently selected dashboards
   */
  selectedDashboard?: string;
  /**
   * The current responsive breakpoint of the dashboard
   */
  currentBreakpoint?: Breakpoint;
  /**
   * This holds changes to layouts coming from the dashboard component.
   * This are not applied to main model until a save operation takes places.
   */
  newLayouts?: Layouts;
  /**
   * this records the last saved version of the dashboards, so we can avoid api calls that
   * do nothing
   */
  lastSavedDashboards?: MNIDashboard[];
  highlightedWidgets: { [k: string]: boolean };
};

export type DashboardAction =
  | { type: 'dashboardsLoaded'; dashboards: MNIDashboard[] }
  | { type: 'recordSavedDashboards'; dashboards?: MNIDashboard[] }
  | { type: 'selectDashboard'; dashboard: MNIDashboard; sessionId: string }
  | { type: 'addWidget'; id: string; save?: boolean; sessionId: string }
  | { type: 'highlightWidget'; id: string }
  | { type: 'unHighlightWidget'; id: string }
  | { type: 'removeWidget'; id: string; sessionId: string }
  | { type: 'setCurrentDashboardName'; name: string }
  | {
      type: 'createDashboard';
      name: string;
      id: string;
      layouts?: Layouts;
      preferences?: MNIDashboardWidgetPreferences;
      sessionId: string;
    }
  | { type: 'deleteCurrentDashboard'; id?: string; sessionId: string }
  | { type: 'stopEditingCurrentDashboard' }
  | { type: 'saveCurrentDashboard'; dashboard?: MNIDashboard; sessionId: string }
  | { type: 'editCurrentDashboard' }
  | { type: 'setBreakpoint'; breakpoint: Breakpoint }
  | { type: 'setLayouts'; layout: Layouts; breakpoint: Breakpoint }
  | { type: 'failure'; error: string };

const log = (...args: any[]) => {
  console.warn('REDUCER', ...args);
};

const removeTransientLayouts = (state: DashboardState): DashboardState => {
  delete state.newLayouts;

  return state;
};

export type DashboardContentReducer = Reducer<DashboardState, DashboardAction>;

export const dashboardContentReducerWrapper: Reducer<DashboardState, DashboardAction> = (
  state: DashboardState,
  action: DashboardAction,
): DashboardState => {
  log('reducer action', action, state);

  const result = dashboardContentReducer(state, action);

  log('reducer result', result);

  return result;
};
export function dashboardContentReducer(
  state: DashboardState,
  action: DashboardAction,
): DashboardState {
  //these allow quick access to the selected dashboard
  const selectedDashboard =
    // eslint-disable-next-line
    state.dashboards && state.dashboards.find(value => value.id == state.selectedDashboard);
  const selectedDashboardIndex =
    // eslint-disable-next-line
    state.dashboards && state.dashboards.findIndex(value => value.id == state.selectedDashboard);

  const subject = authService.getSubjectData();

  switch (action.type) {
    case 'dashboardsLoaded': {
      const savedDashboardId = localStorage.getItem('selectedDashboard');

      console.info('Using selected dashboard', savedDashboardId);

      let selectedDashboardId: string | undefined = undefined;

      // check if the saved dashboard is still here, otherwise, use a
      //sensible default
      if (action.dashboards.length > 0) {
        // eslint-disable-next-line
        selectedDashboardId = action.dashboards.find(value => value.id == savedDashboardId)?.id;

        if (!selectedDashboard) selectedDashboardId = action.dashboards[0].id;
      } else log('no dashboards');

      return {
        loading: false,
        editing: false,
        dirty: false,
        dashboards: [...action.dashboards],
        selectedDashboard: selectedDashboardId,
        newLayouts: undefined,
        lastSavedDashboards: JSON.parse(JSON.stringify(action.dashboards)),
        highlightedWidgets: {},
      };
    }
    case 'recordSavedDashboards': {
      return {
        ...state,
        lastSavedDashboards: JSON.parse(JSON.stringify(action.dashboards)),
      };
    }
    case 'highlightWidget':
      state.highlightedWidgets[action.id] = true;

      return {
        ...state,
        highlightedWidgets: {
          ...state.highlightedWidgets,
        },
      };
    case 'unHighlightWidget':
      state.highlightedWidgets[action.id] = false;

      return {
        ...state,
        highlightedWidgets: {
          ...state.highlightedWidgets,
        },
      };

    case 'selectDashboard':
      if (!state.dashboards) return state;

      analyticsService.recordAnalyticsEvent(AnalyticsEvent.DASHBOARD_CHANGED, {
        subject,
        object: {
          type: AnalyticsTypes.DASHBOARD,
          id: action.dashboard.id,
          title: action.dashboard.name,
        },
        session: {
          id: action.sessionId,
        },
      });

      return removeTransientLayouts({
        ...state,
        selectedDashboard: action.dashboard.id,
      });

    case 'addWidget': {
      if (!state.dashboards || !selectedDashboard) {
        console.error('Cannot add widget', state);
        return state;
      }

      if (!selectedDashboard.layouts[state.currentBreakpoint || 'md'])
        selectedDashboard.layouts[state.currentBreakpoint || 'md'] = [];

      for (let layoutsKey in selectedDashboard.layouts) {
        selectedDashboard.layouts[layoutsKey].push({ w: 4, h: 4, x: 0, y: -1, i: action.id });
      }

      selectedDashboard.layouts = { ...selectedDashboard.layouts };
      state.dashboards[selectedDashboardIndex] = { ...selectedDashboard };

      analyticsService.recordAnalyticsEvent(AnalyticsEvent.WIDGET_ADDED, {
        subject,
        object: { type: AnalyticsTypes.WIDGET, id: action.id },
        session: {
          id: action.sessionId,
        },
      });

      return removeTransientLayouts({
        ...state,
        ...(action.save === true ? { dirty: true } : {}),
        dashboards: [...state.dashboards],
      });
    }
    case 'removeWidget': {
      if (!selectedDashboard) return state;

      //remove from existing layouts
      for (let breakpoint in selectedDashboard.layouts) {
        if (!selectedDashboard.layouts[breakpoint]) selectedDashboard.layouts[breakpoint] = [];

        selectedDashboard.layouts[breakpoint] = selectedDashboard.layouts[breakpoint].filter(
          // eslint-disable-next-line
          value => value.i != action.id,
        );

        console.log('Removing', breakpoint, action.id, selectedDashboard.layouts[breakpoint]);
      }

      //remove item from new layouts
      if (state.newLayouts) {
        for (let breakpoint in state.newLayouts) {
          state.newLayouts[breakpoint] = state.newLayouts[breakpoint].filter(
            // eslint-disable-next-line
            value => value.i != action.id,
          );
        }

        selectedDashboard.layouts = { ...state.newLayouts };
      }

      state.dashboards[selectedDashboardIndex].layouts = { ...selectedDashboard.layouts };
      state.dashboards[selectedDashboardIndex] = { ...selectedDashboard };

      analyticsService.recordAnalyticsEvent(AnalyticsEvent.WIDGET_REMOVED, {
        subject,
        object: { type: AnalyticsTypes.WIDGET, id: action.id },
        session: {
          id: action.sessionId,
        },
      });

      return removeTransientLayouts({
        ...state,
        dashboards: [...state.dashboards],
      });
    }
    case 'setCurrentDashboardName':
      if (selectedDashboard) selectedDashboard.name = action.name;

      return {
        ...state,
        dashboards: [...state.dashboards],
        dirty: true,
      };

    case 'editCurrentDashboard':
      if (!state.selectedDashboard) return state;
      return {
        ...state,
        editing: true,
      };
    case 'stopEditingCurrentDashboard':
      return removeTransientLayouts({
        ...state,
        editing: false,
        dirty: false,
        saveWhileEditing: false,
      });

    case 'saveCurrentDashboard':
      // apply new layouts then save
      if (selectedDashboard && state.currentBreakpoint && state.newLayouts) {
        selectedDashboard.layouts = { ...state.newLayouts };

        for (let layoutsKey in selectedDashboard.layouts) {
          // eslint-disable-next-line
          if (layoutsKey != state.currentBreakpoint) delete selectedDashboard.layouts[layoutsKey];
        }
      }

      if (action.dashboard?.id) {
        analyticsService.recordAnalyticsEvent(AnalyticsEvent.DASHBOARD_UPDATED, {
          subject,
          object: {
            type: AnalyticsTypes.DASHBOARD,
            id: action.dashboard.id,
            title: action.dashboard.name,
          },
          session: {
            id: action.sessionId,
          },
        });
      }

      return removeTransientLayouts({
        ...state,
        dirty: true,
        saveWhileEditing: true,
      });
    case 'createDashboard':
      if (!state.dashboards) return state;

      if (state.dashboards.some(value => value.id === action.id)) return state;

      let layouts: Layouts = action.layouts ?? {};

      //apply layouts from template
      if (state.currentBreakpoint && action.layouts) {
        const layoutValue: Layout[] | undefined = action.layouts
          ? action.layouts[state.currentBreakpoint]
          : undefined;

        if (layoutValue) {
          layouts = { [state.currentBreakpoint]: layoutValue };
        }
      }

      const newDashboard: MNIDashboard = {
        name: action.name,
        id: action.id,
        layouts: layouts ?? {},
        widgetPreferences: action.preferences ?? {},
      };

      state.dashboards.push(newDashboard);
      state.selectedDashboard = newDashboard.id;

      analyticsService.recordAnalyticsEvent(AnalyticsEvent.DASHBOARD_CREATED, {
        subject,
        object: {
          type: AnalyticsTypes.DASHBOARD,
          id: action.id,
          title: action.name,
        },
        session: {
          id: action.sessionId,
        },
      });

      return removeTransientLayouts({
        ...state,
        dashboards: [...state.dashboards],
        editing: true,
      });
    case 'deleteCurrentDashboard': {
      if (state.dashboards) {
        const updatedDashboards = state.dashboards.filter(value => value.id !== action.id);

        state.selectedDashboard =
          updatedDashboards && updatedDashboards.length > 0 ? updatedDashboards[0].id : undefined;

        state.dashboards = updatedDashboards;
      }

      analyticsService.recordAnalyticsEvent(AnalyticsEvent.DASHBOARD_DELETED, {
        subject,
        object: {
          type: AnalyticsTypes.DASHBOARD,
          id: action.id,
        },
        session: {
          id: action.sessionId,
        },
      });

      return removeTransientLayouts({
        ...state,
        dirty: true,
      });
    }
    case 'setBreakpoint':
      return {
        ...state,
        currentBreakpoint: action.breakpoint,
      };
    case 'setLayouts':
      if (!selectedDashboard || !selectedDashboard.layouts) return state;

      if (!state.editing) return state;

      selectedDashboard.layouts[action.breakpoint] = action.layout[action.breakpoint];

      return {
        ...state,
        newLayouts: { ...action.layout },
        dirty: false,
      };
    default:
      return state;
  }
}
