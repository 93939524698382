import { useContext } from 'react';
import { ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { initialFilters, SearchContext } from '@/components/dashboard/search/search-context';

export default function Search() {
  const { search, setSearch, searchResults, setFilters, setHideSearchResults, searchRef, loading } =
    useContext(SearchContext)!;

  return (
    <div
      className="me-2 position-relative"
      ref={searchRef}
      onClick={() => search !== '' && setHideSearchResults(false)}
    >
      <input
        className="border border-tertiary-500 rounded px-5 h-100 focus-ring shadow-none"
        value={search}
        onChange={event => setSearch(event.target.value)}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') searchResults();
        }}
      />
      <FontAwesomeIcon
        icon={faMagnifyingGlass}
        className="position-absolute start-0 top-50 translate-middle-y ms-3 text-tertiary-500"
      />
      {loading ? (
        <span className="position-absolute end-0 top-50 translate-middle-y me-3">
          <ClipLoader size={12} color={'var(--bs-tertiary-500)'} />
        </span>
      ) : (
        <FontAwesomeIcon
          icon={faXmark}
          role="button"
          onClick={e => {
            e.stopPropagation();
            setSearch('');
            setHideSearchResults(true);
            setFilters(initialFilters);
          }}
          className="position-absolute end-0 top-50 translate-middle-y me-3 text-tertiary-500"
        />
      )}
    </div>
  );
}
