export enum AnalyticsEvent {
  ARTICLE_BOOKMARKED = 'article_bookmarked',
  ARTICLE_UN_BOOKMARKED = 'article_un_bookmarked',
  ARTICLE_POPOUT = 'article_popout',
  ARTICLE_MODAL = 'article_modal',
  ARTICLE_PDF_VIEWED = 'article_pdf_viewed',

  NOTIFICATION_CLICKED = 'notification_clicked',

  WIDGET_ADDED = 'widget_added',
  WIDGET_REMOVED = 'widget_removed',
  WIDGET_SHARED = 'widget_shared',
  WIDGET_POPOUT = 'widget_popout',
  WIDGET_CONFIG_CHANGED = 'widget_config_changed',

  DASHBOARD_CHANGED = 'dashboard_changed',
  DASHBOARD_CREATED = 'dashboard_created',
  DASHBOARD_DELETED = 'dashboard_deleted',
  DASHBOARD_SHARED = 'dashboard_shared',
  DASHBOARD_UPDATED = 'dashboard_updated',

  HSC_EVENT_CLICKED = 'hsc_event_clicked',
  HSC_FORECAST_EDITED = 'hsc_forecast_edited',
  HSC_READER_SCHEDULED = 'hsc_reader_scheduled',
  HSC_EVENT_ADDED_TO_CALENDAR = 'hsc_event_added_to_calendar',

  SEARCH_COMMAND = 'search_command',

  REQUEST_UPGRADE = 'request_upgrade',

  SEARCH_ACTION = 'search_action',
}

export enum AnalyticsSubTypeEvent {
  SCROLLER_FILTERS_CHANGED = 'scroller_filters_changed',
  WORLD_CLOCK_LOCATION_CHANGED = 'world_clock_location_changed',
  PINNED_ARTICLE_COLOR_CHANGE = 'pinned_article_color_change',
  PINNED_SERIES_COLOR_CHANGE = 'pinned_series_color_change',

  // HSC Specific
  AUCTION_EVENT = 'auction_event',
  DATA_EVENT = 'data_event',
}

// Search Action Specific
export enum AnalyticsActionType {
  EXPAND = 'expand_modal',
  ARTICLE_BOOKMARKED = 'article_bookmarked',
  ARTICLE_UN_BOOKMARKED = 'article_un_bookmarked',
  COPY_PATH = 'share',
  WIDGET_NAVIGATE = 'widget_navigate',
  PIN = 'pinned',
  UNPIN = 'unpinned',
  OPEN_TARGET_BLANK = 'open_target_blank',
}
