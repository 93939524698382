import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { faCalendar, faList, faNewspaper, faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { searchApi } from '@/api';
import {
  SearchPagination,
  SearchQueryFilters,
  SearchQueryFilterType,
  SearchResponse,
  SearchResults,
} from '@/model/search/search';
import useDebounce from '@/hooks/use-debounce';
import { analyticsService, authService } from '@/services';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import useSessionId from '@/components/auth/session-provider';

export const searchQueryFilterTypes: SearchQueryFilterType[] = [
  'newsletter',
  'bullet',
  'headline',
  'story',
  'article',
  'event',
  'genre',
];
export type SearchFilterType = 'bullet' | 'article' | 'event' | 'genre';
export const searchFiltersTypes: SearchFilterType[] = ['article', 'bullet', 'event', 'genre'];

export const SearchFiltersTypeToIcon = new Map<SearchQueryFilterType, JSX.Element>([
  [
    'article',
    <FontAwesomeIcon size="xs" icon={faNewspaper} style={{ color: 'var(--bs-primary)' }} />,
  ],
  ['bullet', <FontAwesomeIcon size="xs" icon={faList} style={{ color: 'var(--bs-primary)' }} />],
  ['event', <FontAwesomeIcon size="xs" icon={faCalendar} style={{ color: 'var(--bs-primary)' }} />],
  [
    'genre',
    <FontAwesomeIcon size="xs" icon={faThumbTack} style={{ color: 'var(--bs-primary)' }} />,
  ],
]);

export enum SearchFiltersTypeToColor {
  article = 'pills-orange',
  bullet = 'secondary',
  event = 'success',
  genre = 'warning',
}

interface SearchContextType {
  search: string;
  searchRef: React.RefObject<HTMLDivElement>;
  modalRef: React.MutableRefObject<HTMLDivElement | null>;
  loading: boolean;
  setSearch: (value: string) => void;
  pagination: SearchPagination;
  setPagination: (pagination: SearchPagination) => void;
  filters: SearchQueryFilters;
  setFilters: (filters: SearchQueryFilters) => void;
  results: SearchResults[];
  searchResults: () => Promise<void>;
  hideSearchResults: boolean;
  setHideSearchResults: (hide: boolean) => void;
  selectedArticle: string | undefined;
  setSelectedArticle: (uri: string | undefined) => void;
}

export const SearchContext = createContext<SearchContextType | undefined>(undefined);
export const initialFilters: SearchQueryFilters = {
  search: '',
  types: ['newsletter', 'bullet', 'headline', 'story', 'event', 'genre'],
};
export function SearchProvider({ children }: { children: React.ReactNode }) {
  const sessionId = useSessionId();
  const subject = authService.getSubjectData();

  const [search, setSearch] = useState('');
  const searchRef = useRef(null);
  const modalRef = useRef(null);
  const [hideSearchResults, setHideSearchResults] = useState(true);
  const debouncedSearch = useDebounce(search, 500);
  const [pagination, setPagination] = useState<SearchPagination>({ page: 1, pageSize: 3 });
  const [filters, setFilters] = useState<SearchQueryFilters>(initialFilters);
  const [results, setResults] = useState<SearchResults[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<string>();

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
  const sendDebouncedSearchEvent = (searchValue: string, results: SearchResults[]) => {
    if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current);

    debounceTimerRef.current = setTimeout(() => {
      analyticsService.recordAnalyticsEvent(AnalyticsEvent.SEARCH_COMMAND, {
        subject,
        object: {
          type: AnalyticsTypes.SEARCH,
          config: {
            search: searchValue,
            results: results.map(r => ({
              uri: r.uri,
              title: r.title,
            })),
          },
        },
        session: {
          id: sessionId,
        },
      });
    }, 5000); // 5 seconds
  };

  const searchResults = useCallback(async () => {
    setLoading(true);

    // Article is a special case
    const articleTypes: SearchQueryFilterType[] = ['story', 'newsletter', 'headline'];
    const hasAllArticleTypes = articleTypes.every(t => filters.types?.includes(t));
    const types: any[] = filters.types?.filter(f => !articleTypes.includes(f)) || [];
    if (hasAllArticleTypes) types.push(articleTypes);

    const response: (SearchResponse | undefined)[] = await Promise.all(
      types.map(
        async t =>
          await searchApi.search({
            pagination,
            filters: {
              ...filters,
              types: typeof t === 'string' ? [t] : t,
              search: debouncedSearch,
            },
          }),
      ),
    );
    setLoading(false);
    if (response) {
      let results: SearchResults[] = [];
      response.forEach(r => (results = results.concat(r?.results || [])));
      setResults(results);
      if (debouncedSearch && debouncedSearch.length > 2) {
        sendDebouncedSearchEvent(debouncedSearch, results);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filters, debouncedSearch]);

  useEffect(() => {
    searchResults();
  }, [searchResults]);

  useEffect(() => {
    if (debouncedSearch === '') setFilters(initialFilters);
  }, [debouncedSearch]);

  interface ToggleSearchResultsDetail {
    open: boolean;
  }

  const toggleSearchResults = useMemo(
    () =>
      new CustomEvent<ToggleSearchResultsDetail>('toggleSearchResults', {
        detail: { open: !hideSearchResults },
      }),
    [hideSearchResults],
  );

  useEffect(() => {
    const handleToggleSearchResults = (e: CustomEvent<ToggleSearchResultsDetail>) => {
      if (e.detail.open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    };

    document.addEventListener('toggleSearchResults', handleToggleSearchResults as EventListener);

    return () => {
      document.removeEventListener(
        'toggleSearchResults',
        handleToggleSearchResults as EventListener,
      );
    };
  }, []);

  useEffect(() => {
    document.dispatchEvent(toggleSearchResults);
  }, [hideSearchResults, toggleSearchResults]);

  return (
    <SearchContext.Provider
      value={{
        search,
        searchRef,
        modalRef,
        loading,
        setSearch,
        pagination,
        setPagination,
        filters,
        setFilters,
        results,
        searchResults,
        hideSearchResults,
        setHideSearchResults,
        selectedArticle,
        setSelectedArticle,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
