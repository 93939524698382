import DataRowEntry from '@/components/data-calendar/rows/data-row-entry';
import { NewsEvent } from '@/model/calendar/news-event';

export default function EventDetailsDataTable({ event, date }: { event: NewsEvent; date: string }) {
  return (
    <table className={'table table-sm '}>
      <thead>
        <tr>
          <th className={'bg-quaternary text-text-dark'}>Figure</th>
          <th className={'bg-quaternary text-text-dark'}></th>
          <th className={'bg-quaternary text-text-dark'}>Fcst</th>
          <th className={'bg-quaternary '}>Act</th>
          <th className={'bg-quaternary text-text-dark'}>Diff</th>
          <th className={'bg-quaternary text-text-dark'}>
            Prev / <span className={'text-body'}>Rev</span>{' '}
          </th>
        </tr>
      </thead>
      <tbody>
        {event.dataSeriesEntries
          .filter(value => !value.isPreviousRevision)
          .map(value => (
            <tr key={'row-' + value.dataSeriesId}>
              <DataRowEntry event={event} dataSeriesEntry={value} date={date} isNext={false} />
            </tr>
          ))}
      </tbody>
    </table>
  );
}
