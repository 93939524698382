import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import upgradeApi from '@/api/upgrade-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import { analyticsService, authService } from '@/services';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import useSessionId from '../auth/session-provider';

interface UpgradePanelProps {
  contentName: string;
}

export default function UpgradePanel(props: UpgradePanelProps) {
  const sessionId = useSessionId();
  const subject = authService.getSubjectData();

  const [requested, setRequested] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [requesting, setRequesting] = useState<boolean>(false);

  const request = {
    userId: authService.getCredentials()?.body?.sub || '',
    application: 'MyMNI',
    content: props.contentName,
  };

  useEffect(() => {
    upgradeApi.getUpgradeStatus(request).then(value => {
      setLoading(false);
      setRequested(value);
    }); // eslint-disable-next-line
  }, []);

  const requestUpgrade = async () => {
    setRequesting(true);
    upgradeApi.createUpgradeRequest(request).then(value => {
      setRequested(true);
      setLoading(false);
      setRequesting(false);

      analyticsService.recordAnalyticsEvent(AnalyticsEvent.REQUEST_UPGRADE, {
        subject,
        object: {
          type: AnalyticsTypes.UPGRADE,
          config: {
            product: props.contentName,
          },
        },
        session: {
          id: sessionId,
        },
      });
    });
  };

  return (
    <div className={'h-100 d-flex flex-column justify-content-around text-center'}>
      <div>
        {!loading && !requested && (
          <>
            <h3>Contact us for access</h3>
            <p>
              MNI is the leading provider of central bank intelligence and analysis on the global
              fixed income, foreign exchange and energy markets.
            </p>
            <p className={'fw-bold'}>
              To access this and more from MNI, please contact us by clicking the button below.
            </p>
            <Button variant={'warning'} disabled={requesting} onClick={requestUpgrade}>
              {requesting ? (
                <Spinner />
              ) : (
                <>
                  <FontAwesomeIcon icon={faPlus} /> Request Access
                </>
              )}
            </Button>
          </>
        )}
        {!loading && requested && (
          <>
            <div className={'fw-bold h3'}> Request Received</div>

            <p>
              You have requested access to this item. We are processing your request and will
              contact you shortly.
            </p>
            <h1>
              <span className={'text-success'}>
                <FontAwesomeIcon icon={faCheck} />{' '}
              </span>
            </h1>
          </>
        )}
        <div>{loading && <Spinner />}</div>
      </div>
    </div>
  );
}
