export enum AnalyticsTypes {
  ARTICLE = 'article',
  EVENT = 'event',
  ARTICLE_SERIES = 'article-series',
  NOTIFICATION = 'notification',
  WIDGET = 'widget',
  DASHBOARD = 'dashboard',
  SEARCH = 'search',
  UPGRADE = 'upgrade',
}
