import axios from 'axios';
import articleApi from './article-api';
import selectApi from './select-api';
import shareApi from './share-api';
import preferencesApi from '@/api/preferences-api';
import opinionsApi from '@/api/opinions-api';
import sectionApi from '@/api/section-api';
import { authService } from '@/services';
import axiosRetry from 'axios-retry';
import upgradeApi from '@/api/upgrade-api';
import searchApi from '@/api/search-api';

axios.defaults.baseURL = process.env.REACT_APP_SELECT_HOST as string;

axios.interceptors.request.clear();
axios.interceptors.request.use(async value => {
  value.headers['Authorization'] = `Bearer ${(await authService.getAccessToken())?.access_token}`;
  return value;
});

axiosRetry(axios, {
  retries: 5, // number of retries
  retryDelay: retryCount => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 1000; // 500ms * retry count
  },
  retryCondition: error => {
    // if retry condition is not specified, by default idempotent requests are retried
    return error?.status ? error.status >= 500 : false;
  },
});

export {
  articleApi,
  selectApi,
  shareApi,
  preferencesApi,
  opinionsApi,
  sectionApi,
  upgradeApi,
  searchApi,
};
