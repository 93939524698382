import { SearchFilterType } from '@/components/dashboard/search/search-context';

export enum SearchFilterTypeToUrl {
  'genre' = '/pinned-series',
  'event' = '/calendar/event',
  'article' = '/article',
  'bullet' = '/article',
}

export function getSearchFilterTypeUrl(type: SearchFilterType): string {
  return SearchFilterTypeToUrl[type];
}

export function getItemTypeAndIdFromUri(uri: string) {
  const match = uri.match(/^([^:]+):(.*)$/);
  let itemType: SearchFilterType | null = null;
  let itemId = '';
  if (match) {
    itemType = match[1] as SearchFilterType;
    itemId = match[2];
  }
  return { itemType, itemId };
}
