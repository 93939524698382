import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

const useSessionId = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);

  useEffect(() => {
    let storedSessionId = sessionStorage.getItem('sessionId');
    if (!storedSessionId) {
      storedSessionId = uuid();
      sessionStorage.setItem('sessionId', storedSessionId);
    }
    setSessionId(storedSessionId);
  }, []);

  return sessionId;
};

export default useSessionId;
