import { CSSProperties } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';

import { NewsEvent } from '@/model/calendar/news-event';
import { analyticsService, authService, calendarLinkService } from '@/services';
import { AnalyticsEvent, AnalyticsSubTypeEvent } from '@/services/analytics/analytics-event';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import useSessionId from '../auth/session-provider';

enum CalendarType {
  google = 'google',
  outlook = 'outlook',
  office365 = 'office365',
  ical = 'ical',
}

export default function AddToCalendarButton({
  event,
  type,
  className,
  style,
}: {
  style?: CSSProperties | undefined;
  type?: 'auction' | 'data';
  className?: string;
  event: NewsEvent;
}) {
  const sessionId = useSessionId();
  const subject = authService.getSubjectData();

  const onLinkClick = (calendarType: CalendarType) => {
    analyticsService.recordAnalyticsEvent(AnalyticsEvent.HSC_EVENT_ADDED_TO_CALENDAR, {
      subject,
      object: {
        type: AnalyticsTypes.EVENT,
        eventSubType:
          type === 'auction'
            ? AnalyticsSubTypeEvent.AUCTION_EVENT
            : AnalyticsSubTypeEvent.DATA_EVENT,
        id: event.id,
        title: `[${event.country}] ${event.title}`,
        config: {
          date: event.date,
          ...(type === 'auction'
            ? {
                auctionHolderId:
                  event.auction?.auctionProducts.length > 0
                    ? event.auction?.auctionProducts[0].auctionHolder.id
                    : undefined,
                auctionProducts: event.auction?.auctionProducts?.map(p => ({
                  id: typeof p.id === 'number' ? `${p.id}` : `(${p.id.type}) ${p.id.value}`,
                  title: p.display,
                })),
              }
            : {
                date: event.date,
                dataReleaseId: event.dataReleaseId,
                dataSeriesIds: event.dataSeriesEntries.map(ds => ds.dataSeriesId),
              }),
          instantAnswers: event.instantAnswers?.map(ia => ({
            instantAnswersSeriesId: ia.instantAnswersSeriesId,
            instantAnswersInstanceId: ia.instantAnswersInstanceId,
            questionIds: ia.questions.map(q => q.questionId),
          })),
          calendar: {
            type: calendarType,
          },
        },
      },
      session: {
        id: sessionId,
      },
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle id="dropdown-basic" size="sm" className={className} variant={'link'}>
        <FontAwesomeIcon icon={faCalendarPlus} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={'bg-quaternary border-warning'}>
        <Dropdown.Item
          href={calendarLinkService.createGoogleLink(event)}
          target={'_blank'}
          onClick={() => onLinkClick(CalendarType.google)}
        >
          Add to Google Calendar
        </Dropdown.Item>
        <Dropdown.Item
          href={calendarLinkService.createOutlookLink(event)}
          target={'_blank'}
          onClick={() => onLinkClick(CalendarType.outlook)}
        >
          Add to Outlook Live Calendar
        </Dropdown.Item>
        <Dropdown.Item
          href={calendarLinkService.createOffice365Link(event)}
          target={'_blank'}
          onClick={() => onLinkClick(CalendarType.office365)}
        >
          Add to Office 365 Calendar
        </Dropdown.Item>
        <Dropdown.Item
          href={calendarLinkService.createICalDownloadLink(event)}
          download={'event.ics'}
          onClick={() => onLinkClick(CalendarType.ical)}
        >
          Download ICal File
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
