import {
  SearchFiltersTypeToColor,
  SearchFiltersTypeToIcon,
  SearchFilterType,
} from '@/components/dashboard/search/search-context';

export default function SearchTypeIcon({ type }: { type: SearchFilterType }) {
  return (
    <div
      style={{ width: '20px', height: '20px' }}
      className={`p-1 bg-${SearchFiltersTypeToColor[type]} flex-shrink-0 rounded d-flex align-items-center justify-content-center`}
    >
      {SearchFiltersTypeToIcon.get(type)}
    </div>
  );
}
