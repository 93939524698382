import { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Article } from '@/model/article';
import { openPopup } from '@/util/pop-up-util';
import { AnalyticsEvent } from '@/services/analytics/analytics-event';
import { analyticsService, authService } from '@/services';
import { articleApi } from '@/api';
import DateFormat from '@/components/shared/date-format';
import ArticleBodyDisplay from '@/components/articles/article-body-display';
import Loading from '@/components/shared/loading';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import styles from '@/components/articles/article-list.module.scss';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import useSessionId from '../auth/session-provider';

function ArticleDisplay({ article }: { article: Article }) {
  return (
    <div className={'h-100 h-100 overflow-auto d-flex flex-column container-element '} style={{}}>
      <div className={''}>
        <div className={'d-flex justify-content-between'}>
          <div className={'small '}>
            <div>
              <DateFormat format={'MMM D, h:mm a'} date={article.firstcreated} />,{' '}
              <strong>{dayjs(article.firstcreated).fromNow()}</strong>
            </div>
          </div>
          <div>
            {article.byline && <div className={'small fst-italic'}>By {article.byline}</div>}
          </div>
        </div>
      </div>

      <div className={`h-100 overflow-auto container-responsive-text mt-3 ${styles.articleModal}`}>
        <ArticleBodyDisplay article={article} xhtml={article.body_xhtml} />
      </div>
    </div>
  );
}

export default function ArticleModal({
  articleId,
  onClose,
}: {
  articleId?: string;
  onClose: () => void;
}) {
  const [article, setArticle] = useState<Article>();
  const [loading, setLoading] = useState<boolean>(false);

  const sessionId = useSessionId();
  const subject = authService.getSubjectData();

  useEffect(() => {
    setLoading(true);
    if (articleId) {
      articleApi
        .getArticle(articleId)
        .then(setArticle)
        .finally(() => setLoading(false));
    } else {
      setArticle(undefined);
      setLoading(false);
    }
  }, [articleId]);

  useEffect(() => {
    if (article) {
      analyticsService.recordAnalyticsEvent(AnalyticsEvent.ARTICLE_MODAL, {
        subject,
        object: {
          type: AnalyticsTypes.ARTICLE,
          id: article.uri,
          title: article.headline,
          genres: article.genre.map(g => ({ id: `${g.scheme}:${g.code}`, name: g.name })),
          subjects: article.subject.map(s => ({ id: `${s.scheme}:${s.code}`, name: s.name })),
          authors: article.byline,
        },
        session: {
          id: sessionId,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [article]);

  const popOut = () => {
    if (article) {
      openPopup(`/article/${article.uri}`);
      analyticsService.recordAnalyticsEvent(AnalyticsEvent.ARTICLE_POPOUT, {
        subject,
        object: {
          type: AnalyticsTypes.ARTICLE,
          id: article.uri,
          title: article.headline,
          genres: article.genre.map(g => ({ id: `${g.scheme}:${g.code}`, name: g.name })),
          subjects: article.subject.map(s => ({ id: `${s.scheme}:${s.code}`, name: s.name })),
          authors: article.byline,
        },
        session: {
          id: sessionId,
        },
      });
    }
    onClose();
  };

  return (
    <Modal show={articleId != null} onHide={onClose} size={'lg'}>
      <ModalHeader
        className={'align-items-baseline sticky-top'}
        style={{ backgroundColor: 'var(--bs-body-bg' }}
      >
        <span>
          <h3>{article?.headline}</h3>
        </span>
        <div className={'flex-shrink-0'}>
          {article && <BookmarkButton article={article} />}
          <button className={'btn btn-link btn-sm'} onClick={popOut}>
            <FontAwesomeIcon icon={faUpRightFromSquare} />
          </button>
          <button className={'btn btn-link link-danger btn-sm'} onClick={onClose}>
            <FontAwesomeIcon icon={faCircleXmark} />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        {loading && <Loading type={'grid'} />}
        {article && <ArticleDisplay article={article} />}
      </ModalBody>
    </Modal>
  );
}
