import { useEffect, useState } from 'react';
import useSubscription from '@/hooks/use-subscription';
import { Article } from '@/model/article';
import { Permissions } from '@/model/permissions';
import { NewsEvent, NewsEventUtil } from '@/model/calendar/news-event';
import { AuctionObservation } from '@/model/calendar/auction-observation';
import { AuctionProductAttribute } from '@/model/auctions/auction-product-attribute';
import { AuctionProduct } from '@/model/auctions/auction-product';
import { AuctionProductId } from '@/model/auctions/auction-product-id';
import CountryFlag from '@/components/shared/country-flag';
import EventBadges from '@/components/shared/event-badges';
import DateFormat from '@/components/shared/date-format';
import Round from '@/components/shared/round';
import RequirePermissions from '@/components/shared/require-permissions';
import ArticleList from '@/components/articles/article-list';
import { CoverageMap, EventCoverage } from '@/components/data-calendar/details/coverage-classes';

import style from '@/components/articles/article-list.module.scss';
import useWidgetNavigate from '@/widgets/widget-router/use-widget-navigate';
import { articleApi } from '@/api';
import { analyticsService, authService } from '@/services';
import { MessageServiceConstants } from '@/services/messaging/message-service-constants';
import { AnalyticsEvent, AnalyticsSubTypeEvent } from '@/services/analytics/analytics-event';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import AddToCalendarButton from '@/components/shared/add-to-calendar-button';
import useSessionId from '@/components/auth/session-provider';

export default function AuctionDetails({
  events,
  onClose,
  date,
  auctionHolderId,
}: {
  events: NewsEvent[];
  onClose: Function;
  date: string;
  auctionHolderId: string;
}) {
  const sessionId = useSessionId();
  const subject = authService.getSubjectData();

  const errorNotice = (
    <div className={'alert alert-danger'}>
      <p>
        <strong>No Auctions at this time!</strong>
      </p>
      <div
        className={style.aTag}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onClose();
        }}
      >
        Back
      </div>
    </div>
  );

  let [eventState, setEventState] = useState<NewsEvent[]>(events);
  let [coverageMap, setCoverageMap] = useState<CoverageMap>(new CoverageMap());
  const navigate = useWidgetNavigate();

  useEffect(
    () => {
      events.forEach(event => {
        event.coverage?.forEach(value => {
          articleApi.getArticle(value.articleId).then(article => {
            setCoverageMap(coverageMap.addItem(new EventCoverage(value, article)));
          });
        });
      });
    }, // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (sessionId) {
      analyticsService.recordAnalyticsEvent(AnalyticsEvent.HSC_EVENT_CLICKED, {
        subject,
        object: {
          type: AnalyticsTypes.EVENT,
          eventSubType: AnalyticsSubTypeEvent.AUCTION_EVENT,
          id: event.id,
          title: `[${event.country}] ${event.title}`,
          config: {
            date: event.date,
            auctionHolderId,
            auctionProducts: event.auction?.auctionProducts?.map(p => ({
              id: typeof p.id === 'number' ? `${p.id}` : `(${p.id.type}) ${p.id.value}`,
              title: p.display,
            })),
            instantAnswers: event.instantAnswers?.map(ia => ({
              instantAnswersSeriesId: ia.instantAnswersSeriesId,
              instantAnswersInstanceId: ia.instantAnswersInstanceId,
              questionIds: ia.questions.map(q => q.questionId),
            })),
          },
        },
        session: {
          id: sessionId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useSubscription<AuctionObservation[]>(MessageServiceConstants.AUCTION_RESULTS_TOPIC, message => {
    message.forEach(value =>
      eventState.forEach(event => NewsEventUtil.mergeAuctionData(event, value)),
    );
    setEventState([...eventState]);
  });

  if (eventState.length <= 0) {
    return errorNotice;
  }

  const event = eventState[0];

  const auctionItems = eventState
    .flatMap(value => value.auction.auctionProducts)
    .sort((a, b) => (a.display > b.display ? 1 : -1));

  if (auctionItems.length <= 0) {
    return errorNotice;
  }

  const attributesMap: { [s: string]: AuctionProductAttribute } = {};

  auctionItems.forEach(value => {
    value.attributes.forEach(value => {
      attributesMap[value.id] = value;
    });
  });

  const attributes = Object.values(attributesMap).sort((a, b) => {
    if (a.displayOrder === b.displayOrder) return a.display > b.display ? 1 : -1;
    else return a.displayOrder - b.displayOrder;
  });
  const announcementData = attributes
    .filter(value => value.availability === 'ANNOUNCEMENT')
    .filter(value => value.id?.toLowerCase() !== 'display');
  //const midData = attributes.filter(value => value.availability === 'MIDPOINT');
  const endData = attributes.filter(value => value.availability === 'END');

  const auctionAttributeById = (product: AuctionProduct, id: string) => {
    return product.attributes.find(value => value.id === id);
  };

  const attributeRow = (attribute: AuctionProductAttribute) => (
    <tr key={`attribute-row-${attribute.id}`}>
      <td className={'text-text-dark w-25'}>{attribute.display}</td>
      {auctionItems.map(product => {
        const attributeValue = auctionAttributeById(product, attribute.id);

        return (
          <td
            key={`attribute-value-${attribute.id}-${(product.id as AuctionProductId).value}`}
            className={`${attributeValue ? '' : 'bg-body-secondary'} text-center`}
          >
            <Round
              value={auctionAttributeById(product, attribute.id)?.value}
              addTrailingZeros={true}
              decimalPrecision={auctionAttributeById(product, attribute.id)?.decimalPrecision}
            />
          </td>
        );
      })}
      <td className={'text-muted text-end w-25'}>
        {attribute.scale} {attribute.type}
      </td>
    </tr>
  );

  return (
    <div className="container-element">
      <div className={'sticky-top bg-body container-responsive-text'}>
        <div className={'d-flex'}>
          <div className={'me-auto'}>
            <h4>
              <CountryFlag country={event.country} height={20} />
              &nbsp;
              <EventBadges event={event} />
              &nbsp;
              {auctionItems[0].auctionHolder.display} Auctions @{' '}
              <DateFormat format={'h:mm A'} date={event.date} />
            </h4>
            <div className={'text-muted'}>
              <h6>
                {' '}
                <DateFormat format={'MMM D, h:mm A'} date={event.date} />{' '}
              </h6>
            </div>
          </div>

          <div className={'text-end'}>
            <div className={'mt-1'}>
              <span className={'d-inline-block mb-1'}>
                <AddToCalendarButton event={event} type={'auction'} className="btn-menu" />
              </span>
              <span className={'mb-1 ms-1'}>
                <button
                  onClick={() => onClose()}
                  style={{ fontFamily: 'Inter' }}
                  className={'btn btn-sm btn-outline-secondary text-secondary btn-menu'}
                >
                  Back
                </button>
              </span>
            </div>
          </div>
        </div>

        <hr />
      </div>

      <RequirePermissions deny={Permissions.EXCLUDE_NEWS}>
        {coverageMap
          ?.coverageTypes()
          .sort((a, b) => {
            if (a.display < b.display) return -1;
            else if (a.display < b.display) return 1;
            return 0;
          })
          .map(value => {
            return (
              <div className={'mb-3 container-responsive-text'} key={'coverage-' + value.name}>
                <h5 className={'calendar-section-header'}>
                  <span className={'text-secondary'}> {value.display}</span> Coverage
                </h5>

                <ArticleList
                  id={`coverage-${value.name}`}
                  articles={coverageMap?.articlesByType(value.name)}
                  onArticleClick={(article: Article) =>
                    navigate(`/calendar/article/${article.uri}`)
                  }
                />
              </div>
            );
          })}
      </RequirePermissions>

      <table className={'table container-responsive-text'}>
        <thead>
          <tr>
            <th className={'bg-quaternary'}></th>
            {auctionItems.map(product => (
              <th
                className={'bg-quaternary text-text-dark text-center'}
                key={`auction-product-header-${(product.id as AuctionProductId).value}`}
              >
                <div>
                  {product.attributes.find(attribute => attribute.id?.toLowerCase() === 'display')
                    ?.value || product.display}{' '}
                  <span className={'text-muted'}>({(product.id as AuctionProductId).value})</span>
                </div>
              </th>
            ))}
            <th className={'bg-quaternary'}></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2 + auctionItems.length} className={'bg-body-secondary text-center'}>
              <span className={'text-success-light'}>Announcement Data</span>
            </td>
          </tr>
          {announcementData.map(attributeRow)}
          <tr>
            <td colSpan={2 + auctionItems.length} className={'bg-body-secondary text-center'}>
              <span className={'text-tertiary'}>Result Data</span>
            </td>
          </tr>
          {endData.map(attributeRow)}
        </tbody>
      </table>
    </div>
  );
}
