import React, { useEffect, useRef, useState } from 'react';
import { NewsWidget } from '../news-widget';
import { WidgetHost } from '../widget-host';
import { RouteObject } from 'react-router-dom';
import Loading from '@/components/shared/loading';
import { authService } from '@/services';
import axios from 'axios';
import { Permissions } from '@/model/permissions';

function ChatMainView() {
  const [loading, setLoading] = useState(true);
  const [hidden, setHidden] = useState(true);
  const [chatToken, setChatToken] = useState<string>();

  const iframe = useRef<HTMLIFrameElement | null>(null);

  const setPath = (s: string) => {
    const win = iframe.current?.contentWindow;

    if (win) {
      win.postMessage(
        {
          externalCommand: 'go',
          path: s,
        },
        '*',
      );
    }
  };

  useEffect(() => {
    // ✅ Define event handler function
    const handleMessage = (event: MessageEvent) => {
      if (event.data.eventName === 'startup') {
        setLoading(false);
      }
    };

    // ✅ Attach event listener when component mounts
    window.addEventListener('message', handleMessage);

    return () => {
      // ✅ Remove event listener when component unmounts
      window.removeEventListener('message', handleMessage);
    };
  }, []); // Empty dependency array ensures it runs only once

  useEffect(() => {
    const credentials = authService.getCredentials();
    if (credentials?.token.access_token && (!chatToken || chatToken.length === 0)) {
      axios
        .post('https://apis.marketnews.com/rocket-chat/sso', {
          auth0Token: credentials.token.access_token,
        })
        .then(response => {
          if (response.status === 200) {
            setChatToken(response.data.token);
          }
        })
        .catch(err => console.log(`ERROR: ${JSON.stringify(err)}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading || !chatToken || chatToken.length === 0) return;

    iframe.current!.contentWindow!.postMessage(
      {
        externalCommand: 'login-with-token',
        token: chatToken,
      },
      '*',
    );

    setTimeout(() => {
      setHidden(false);
    }, 1000);
  }, [loading, chatToken]);

  return (
    <>
      <div
        className={`ecp d-flex flex-column ${hidden ? 'd-none' : ''}`}
        style={{ height: '100%' }}
      >
        <select
          className={'form-select form-select-sm'}
          onChange={event => setPath(event.target.value)}
        >
          <option value={'/channel/dm'}>My DM Team</option>
          <option value={'/channel/credit'}>My Credit Team</option>
          <option value={'/channel/policy'}>My Policy Team</option>
        </select>
        <iframe
          title="chat"
          src={'https://chat.marketnews.com/channel/dm?layout=embedded'}
          className={'w-100 h-100'}
          ref={iframe}
        />
      </div>
      {hidden && (
        <div>
          <Loading />
        </div>
      )}
    </>
  );
}

export class ChatWidget implements NewsWidget {
  defaultTitle: string = 'Chat';
  readonly id = '/chat';

  async hasPermission(permissions: string[]) {
    return permissions.some(value => value === Permissions.ALPHA_ACCESS);
  }

  shouldHideToUser(permissions: string[]): boolean {
    //by default should be false
    return !permissions.some(value => value === Permissions.ALPHA_ACCESS);
  }

  initialize(host: WidgetHost) {}
  get headerPanel(): JSX.Element {
    return <>💬 Chat</>;
  }

  headerActions({ children }: { children?: React.ReactNode }): JSX.Element {
    return <>{children}</>;
  }

  get shortTitle(): JSX.Element {
    return <>💬</>;
  }

  get mainPanel(): JSX.Element {
    return <ChatMainView />;
  }

  get routes(): RouteObject[] {
    return [
      {
        path: `/chat`,
        element: <ChatMainView />,
      },
    ];
  }

  get defaultRoute(): string {
    return `/chat`;
  }
}
