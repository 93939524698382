import { useEffect } from 'react';

export function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  ignoreRefs: React.RefObject<HTMLElement>[] = [],
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node;

      if (ignoreRefs.some(ignoreRef => ignoreRef.current?.contains(target))) return;

      if (ref.current && !ref.current.contains(target)) callback();
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, ignoreRefs]);
}
