import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShareNodes,
  faUpRightFromSquare,
  faUpRightAndDownLeftFromCenter,
  faThumbtack,
  faThumbtackSlash,
  faCalendarDay,
} from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { articleApi, selectApi } from '@/api';
import { convertWidgetToReactPath } from '@/widgets/widget-router/convert-widget-to-react-path';
import { openPopup } from '@/util/pop-up-util';
import {
  getItemTypeAndIdFromUri,
  getSearchFilterTypeUrl,
} from '@/util/get-search-filter-url-by-type';
import { SearchResults } from '@/model/search/search';
import { Article } from '@/model/article';
import { NewsEvent } from '@/model/calendar/news-event';
import useWidgetHost from '@/hooks/use-widget-host';
import { analyticsService, authService } from '@/services';
import {
  AnalyticsEvent,
  AnalyticsSubTypeEvent,
  AnalyticsActionType,
} from '@/services/analytics/analytics-event';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import ClipboardButton from '@/components/shared/clipboard-button';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import { SearchContext } from '@/components/dashboard/search/search-context';
import useSessionId from '@/components/auth/session-provider';

export default function SearchActions({ item }: { item: SearchResults }) {
  const subject = authService.getSubjectData();
  const sessionId = useSessionId();

  const widgetHost = useWidgetHost();
  const { setHideSearchResults, setSelectedArticle } = useContext(SearchContext)!;
  const { itemType, itemId } = getItemTypeAndIdFromUri(item?.uri);
  const itemPathId =
    itemType === 'article' ? item.filters?.find(f => f.key === 'externalId')?.value || '' : itemId;
  const fetchArticle = async (slug: string) => {
    articleApi.getArticleBySlug(slug).then(article => setSelectedArticle(article.uri));
  };
  const [pinned, setPinned] = useState(
    widgetHost.activeWidgetIds.includes('/pinned-series/' + itemPathId),
  );

  const bookmarkData = {
    uri: item.filters?.find(f => f.key === 'externalId')?.value || '',
    headline: item.short_description || '',
    genre: item.filters?.filter(f => f.key === 'genre') || [],
    subject: item.filters?.filter(f => f.key === 'subject') || [],
  } as unknown as Article;

  const fetchItem = async (type: 'article' | 'event', id: string) => {
    if (type === 'article') return await articleApi.getArticleBySlug(id);
    else return await selectApi.getEvent(id);
  };

  const analyticsEventObject = async (actionType: AnalyticsActionType) => {
    switch (itemType) {
      case 'article':
        const article = (await fetchItem('article', item.slug)) as Article;
        return {
          type: AnalyticsTypes.ARTICLE,
          actionType,
          id: article?.uri,
          title: article.headline,
          genres: article.genre.map(g => ({ id: `${g.scheme}:${g.code}`, name: g.name })),
          subjects: article.subject.map(s => ({ id: `${s.scheme}:${s.code}`, name: s.name })),
          authors: article?.byline,
        };
      case 'event':
        const event = (await fetchItem('event', itemId)) as NewsEvent;
        return {
          type: AnalyticsTypes.EVENT,
          actionType,
          eventSubType: AnalyticsSubTypeEvent.DATA_EVENT,
          id: itemId,
          title: item.title,
          config: {
            date: event.date,
            dataReleaseId: event.dataReleaseId,
            dataSeriesIds: event.dataSeriesEntries.map(entry => entry.dataSeriesId),
            country: event.country,
            instantAnswers: event.instantAnswers?.map(i => ({
              instantAnswersSeriesId: i.instantAnswersSeriesId,
              instantAnswersInstanceId: i.instantAnswersInstanceId,
              questionIds: i.questions?.map(q => q.questionId) || [],
            })),
          },
        };
      case 'genre':
        const { itemType: genreSchema, itemId: genreCode } = getItemTypeAndIdFromUri(itemPathId);
        return {
          type: AnalyticsTypes.ARTICLE_SERIES,
          actionType,
          id: itemId,
          code: genreCode,
          scheme: genreSchema,
          display: item.title,
          description: item.short_description,
          longDescription: item.long_description,
        };
    }
  };

  const togglePinGenre = () => {
    if (pinned) {
      widgetHost.removeWidgetById(`/pinned-series/${itemPathId}`);
      recordAnalyticsEvent(AnalyticsActionType.UNPIN);
    } else {
      widgetHost.addWidgetById(`/pinned-series/${itemPathId}`);
      recordAnalyticsEvent(AnalyticsActionType.PIN);
      setHideSearchResults(true);
    }
    setPinned(!pinned);
  };

  const addHSCWidget = () => {
    recordAnalyticsEvent(AnalyticsActionType.WIDGET_NAVIGATE);
    setHideSearchResults(true);
    widgetHost.setWidgetPath('/calendar', `/event/${itemPathId}`);
  };

  const recordAnalyticsEvent = async (actionType: AnalyticsActionType) => {
    analyticsService.recordAnalyticsEvent(AnalyticsEvent.SEARCH_ACTION, {
      subject,
      object: await analyticsEventObject(actionType),
      session: {
        id: sessionId,
      },
    });
  };

  return (
    <div className="d-flex justify-content-end">
      {itemType === 'article' && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="bookmark-tooltip">Bookmark</Tooltip>}
        >
          <div>
            <BookmarkButton
              article={bookmarkData}
              onToggle={(bookmark: boolean) =>
                recordAnalyticsEvent(
                  bookmark
                    ? AnalyticsActionType.ARTICLE_BOOKMARKED
                    : AnalyticsActionType.ARTICLE_UN_BOOKMARKED,
                )
              }
            />
          </div>
        </OverlayTrigger>
      )}
      {itemType === 'genre' && item.filters?.find(f => f.key === 'pinnable')?.value === 'true' && (
        <button className={'btn btn-link btn-sm p-1 py-0'} onClick={togglePinGenre}>
          {pinned ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="unpin-tooltip">Unpin</Tooltip>}
            >
              <FontAwesomeIcon icon={faThumbtackSlash} />
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="pin-tooltip">Pin</Tooltip>}
            >
              <FontAwesomeIcon icon={faThumbtack} />
            </OverlayTrigger>
          )}
        </button>
      )}
      {itemType && (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="share-tooltip">Share</Tooltip>}
        >
          <button
            className={'btn btn-link btn-sm p-1 py-0'}
            onClick={() => recordAnalyticsEvent(AnalyticsActionType.COPY_PATH)}
          >
            <ClipboardButton
              text={`${window.location.origin}${convertWidgetToReactPath(getSearchFilterTypeUrl(itemType), itemPathId)}`}
            >
              <FontAwesomeIcon icon={faShareNodes} />
            </ClipboardButton>
          </button>
        </OverlayTrigger>
      )}
      {itemType === 'article' ? (
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="share-tooltip">Expand</Tooltip>}
        >
          <div onClick={() => recordAnalyticsEvent(AnalyticsActionType.EXPAND)}>
            <button
              className={'btn btn-link btn-sm p-1 py-0'}
              onClick={() => (itemType ? fetchArticle(item.slug) : undefined)}
            >
              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
            </button>
          </div>
        </OverlayTrigger>
      ) : itemType === 'event' ? (
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="calendar-tooltip">See in calendar</Tooltip>}
        >
          <button className={'btn btn-link btn-sm p-1 py-0'} onClick={addHSCWidget}>
            <FontAwesomeIcon icon={faCalendarDay} />
          </button>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="share-tooltip">Open in new tab</Tooltip>}
        >
          <div onClick={() => recordAnalyticsEvent(AnalyticsActionType.OPEN_TARGET_BLANK)}>
            <button
              className={'btn btn-link btn-sm p-1 py-0'}
              onClick={() =>
                itemType
                  ? openPopup(
                      `${convertWidgetToReactPath(getSearchFilterTypeUrl(itemType), itemPathId)}`,
                    )
                  : undefined
              }
            >
              <FontAwesomeIcon icon={faUpRightFromSquare} />
            </button>
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
}
