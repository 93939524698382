import { SearchQuery, SearchResponse } from '@/model/search/search';
import axios from 'axios';

async function search(body: SearchQuery): Promise<SearchResponse> {
  return axios.post<any>(`/api/search`, body).then(value => value.data as any);
}

const searchApi = {
  search,
};

export default searchApi;
