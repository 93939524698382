import { Form } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { Section } from '@/model/news/section';
import { WidgetContainer } from '@/widgets/widget-container';
import { WidgetContainerContext } from '@/widgets/widget-container/widget-container-context';
import { analyticsService, authService } from '@/services';
import { AnalyticsEvent, AnalyticsSubTypeEvent } from '@/services/analytics/analytics-event';
import { BaseScrollerState } from '@/widgets/widgets/scrollers/base-scroller';
import { AnalyticsTypes } from '@/services/analytics/analytics-types';
import useSessionId from '../auth/session-provider';

const sanitizeName = (s: string) => {
  return s.replaceAll(/bullet(s)?|news/gi, '').trim();
};

function replaceCodes({
  list,
  includedSections,
}: {
  list: { codes: string[]; name: string }[];
  includedSections: Section[];
}) {
  const toAdd: { name: string }[] = [];
  const toFilter: string[] = [];
  let response = [...includedSections];
  list.forEach(item => {
    let isIncluded = true;
    item.codes.forEach(c => {
      isIncluded = !!includedSections.find(s => s.code === c) && isIncluded;
    });
    if (isIncluded) {
      toAdd.push({ name: item.name });
      item.codes.forEach(c => toFilter.push(c));
    }
  });
  toFilter.forEach(c => (response = response.filter(s => s.code !== c)));
  response = response.concat(toAdd as Section[]);
  return response.map(s => ({ name: s.name }));
}

export interface SectionFilterPreferences {
  includedSections: string[];
}

export default function SectionFilters({
  widgetId,
  sections,
  onFilteredSectionsChange,
  onFilteredDataNameChange,
}: {
  widgetId?: string;
  sections: Section[];
  onFilteredSectionsChange: (s: string[]) => void;
  onFilteredDataNameChange: (s?: string) => void;
}) {
  const sessionId = useSessionId();
  const subject = authService.getSubjectData();

  const widgetContainer = useContext<WidgetContainer>(WidgetContainerContext);

  const [state, setState] = useState<{ includedSections: string[]; dirty: boolean }>({
    includedSections:
      widgetContainer.getPreferences<SectionFilterPreferences>()?.includedSections ??
      sections.map(value => value.code),
    dirty: false,
  });

  useEffect(() => {
    if (!state) return;

    onFilteredSectionsChange(state.includedSections);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (!state) return;
    const titleSubstitutions = widgetContainer.getState<BaseScrollerState>()?.titleSubstitutions;
    if (state.includedSections.length > 4 || state.includedSections.length === sections.length)
      onFilteredDataNameChange(undefined);
    else {
      if (!sections) return;

      let remaining: Section[] | { name: string }[] = sections.filter(conceptItem => {
        const qcode = `${conceptItem.code}`;

        return state.includedSections.includes(qcode);
      });

      if (titleSubstitutions?.length)
        remaining = replaceCodes({
          list: titleSubstitutions,
          includedSections: remaining as Section[],
        });

      const remainingNames = remaining.map(value => sanitizeName(value.name));

      let title = '';

      if (remainingNames.length === 1) {
        title = remainingNames[0];
      } else if (remainingNames.length > 1 && remainingNames.length <= 4) {
        const front = remainingNames.slice(0, remainingNames.length - 1);
        const back = remainingNames.slice(remainingNames.length - 1, remainingNames.length);
        title = front.join(', ') + ' and ' + back;
      }

      onFilteredDataNameChange(title);
    }
    // eslint-disable-next-line
  }, [sections, state]);

  const filtersLeft = () => {
    return state?.includedSections.length || 1;
  };

  const excludeSubject = (s: string, exclude: boolean) => {
    console.log('Excluding', s, exclude);
    if (!state) return;

    if (exclude && filtersLeft() <= 1) return;

    let result: string[];
    // eslint-disable-next-line
    if (exclude) result = state.includedSections.filter(value => value != s);
    else result = [...state.includedSections, s];

    analyticsService.recordAnalyticsEvent(AnalyticsEvent.WIDGET_CONFIG_CHANGED, {
      subject,
      object: {
        eventSubType: AnalyticsSubTypeEvent.SCROLLER_FILTERS_CHANGED,
        type: AnalyticsTypes.WIDGET,
        id: widgetId,
        config: result.join(', '),
      },
      session: {
        id: sessionId,
      },
    });

    setState({ includedSections: result, dirty: true });
    widgetContainer.setPreferences<SectionFilterPreferences>({ includedSections: result });
  };

  const SubjectSwitch = ({ value }: { value: Section }) => {
    return (
      <Form.Check
        type={'switch'}
        onChange={event => excludeSubject(value.code, !event.target.checked)}
        checked={state?.includedSections.includes(value.code)}
        label={sanitizeName(value.name)}
        className={'d-inline-block pb-0 mb-0 me-1'}
        style={{ minHeight: 0 }}
      />
    );
  };

  return (
    <>
      {sections.map(value => (
        <SubjectSwitch value={value} key={'ci' + value.code} />
      ))}
    </>
  );
}
