import InfiniScroller from '@/components/scrollers/infini-scroller';
import { useEffect, useState } from 'react';
import { Article, ArticleUtil } from '@/model/article';
import dayjs from 'dayjs';
import { ConceptItemConstants } from '@/model/concept-item';
import AgedDateFormat from '@/components/shared/aged-date-format';
import styles from './mainwire-scroller.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import EmptyStateIndicator from '@/components/shared/empty-state-indicator';
import PinButton from '@/components/pins/pin-button';
import BookmarkButton from '@/components/bookmarks/bookmark-button';
import { opinionsApi } from '@/api';
import { OpinionMap } from '@/model/opinions/opinion-map';
import articleListStyles from '@/components/articles/article-list.module.scss';
import parse, { domToReact } from 'html-react-parser';
import ArticleModal from '@/components/articles/article-modal';

export default function MainwireScroller({
  section,
}: {
  section: string[];
  includedSections: string[];
}) {
  const [articles, setArticles] = useState<Article[]>();
  const [selectedArticle, setSelectedArticle] = useState<string>();
  const [opinions, setOpinions] = useState<OpinionMap>({});

  useEffect(() => {
    if (articles)
      opinionsApi.getOpinionsOfArticles(...articles.map(value => value.uri)).then(setOpinions);
  }, [articles]);

  const isBelled = (a: Article) => {
    return !!a.genre.find(value => {
      // eslint-disable-next-line
      return `${value.scheme}:${value.code}` == ConceptItemConstants.BELL;
    });
  };

  const flash = (index: number, date: string) => {
    if (index === 0 && dayjs(date).isAfter(dayjs().subtract(5, 'minute')))
      return articleListStyles.flashArticle;

    return '';
  };

  const renderHtmlWithBellIcon = (html: string) => {
    return parse(html, {
      replace: (domNode: any) => {
        if (domNode.name === 'li' && domNode.attribs && domNode.attribs['data-belled'] === 'true') {
          // Add the bell icon inside the li element if data-belled="true"
          return (
            <li {...domNode.attribs}>
              <FontAwesomeIcon icon={faBell} /> {domToReact(domNode.children)}
            </li>
          );
        }
      },
    });
  };

  const loadOpinionsOfBatch = (a: Article[]) => {
    opinionsApi.getOpinionsOfArticles(...a.map(value => value.uri)).then(value => {
      for (let valueKey in value) {
        opinions[valueKey] = value[valueKey];
      }

      setOpinions({ ...opinions });
    });
  };

  return (
    <InfiniScroller
      section={section}
      onArticlesChange={setArticles}
      onBatchLoad={loadOpinionsOfBatch}
    >
      <table className={'table overflow-auto table-sm table-hover '}>
        <tbody>
          {articles?.map((value, index) => {
            const flashClass = flash(index, value.firstcreated);

            const isHeadline = ArticleUtil.hasGenre(value, 'HEADLINE');

            return (
              <tr key={'mw-' + value.uri} className={`${flashClass} `}>
                <td className={`text-nowrap text-muted ${flashClass}`}>
                  <AgedDateFormat format={'h:mm a[:]'} date={value.firstcreated} />
                </td>
                <td className={`${styles.mainNormalItem}  w-100 ${flashClass}`}>
                  <div className={'d-flex justify-content-between '}>
                    {/*  eslint-disable-next-line   */}
                    <a
                      href=""
                      className={`text-decoration-none  ${isBelled(value) ? 'link-danger' : 'text-body'}`}
                      onClick={event => {
                        event.stopPropagation();
                        event.preventDefault();
                        setSelectedArticle(value.uri);
                      }}
                    >
                      {isHeadline ? (
                        <div className={`${styles.headlineViewer}`}>
                          {renderHtmlWithBellIcon(value.body_xhtml)}
                        </div>
                      ) : (
                        <>
                          {isBelled(value) && <FontAwesomeIcon icon={faBell} />} {value.headline}
                        </>
                      )}
                    </a>
                    <div className={`text-end ${flashClass} flex-shrink-0`}>
                      <PinButton article={value} />
                      <BookmarkButton article={value} providedOpinions={opinions} />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*  eslint-disable-next-line  */}
      {articles?.length == 0 && (
        <div className={'w-100 h-75 d-flex flex-column align-items-center justify-content-around'}>
          <div className={'w-25'}>
            <EmptyStateIndicator text={'No More items'} />
          </div>
        </div>
      )}
      <ArticleModal onClose={() => setSelectedArticle(undefined)} articleId={selectedArticle} />
    </InfiniScroller>
  );
}
