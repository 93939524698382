import { useContext, useEffect } from 'react';
import dayjs from 'dayjs';

import useIsMobile from '@/hooks/use-is-mobile';
import { SearchResults } from '@/model/search/search';
import SearchTypeIcon from '@/components/dashboard/search/search-type-icon';
import { SearchContext, SearchFilterType } from '@/components/dashboard/search/search-context';
import SearchActions from '@/components/dashboard/search/search-actions';
import CountryFlag from '@/components/shared/country-flag';

export default function SearchResultsList({
  type,
  results,
  expand,
  setExpandedType,
  linesToDisplay,
}: {
  type: SearchFilterType;
  results: SearchResults[];
  expand: boolean | null;
  setExpandedType: (type: SearchFilterType | null) => void;
  linesToDisplay: number;
}) {
  const isMobile = useIsMobile(991);
  const { modalRef, selectedArticle } = useContext(SearchContext)!;

  useEffect(() => {
    if (document.querySelector('.modal')) {
      modalRef.current = document.querySelector('.modal') as HTMLDivElement;
    }
  }, [selectedArticle, modalRef]);

  return (
    <div className="d-flex flex-column" style={{ maxHeight: '100%' }}>
      <div
        className="d-flex align-items-center justify-content-between p-2 pt-1 mb-2 border-bottom"
        style={isMobile ? {} : { position: 'sticky', top: 0, zIndex: 10 }}
      >
        <span className="d-flex align-items-center gap-2">
          <SearchTypeIcon type={type} />
          <span>{type === 'genre' ? 'Article serie' : type[0].toUpperCase() + type.slice(1)}s</span>
        </span>
        <span
          onClick={() => setExpandedType(expand ? null : type)}
          className="fw-bold text-secondary"
          role="button"
        >
          {expand ? 'Back to all results' : 'Show more'}
        </span>
      </div>
      <ul className="list-unstyled overflow-auto px-2" style={{ flexGrow: 1 }}>
        {results.slice(0, expand ? undefined : linesToDisplay).map(result => {
          const country = result.filters?.find(f => f.key === 'country')?.value;
          const title = type === 'event' ? `${result.title} [${country}]` : result.title;
          return (
            <li key={result.uri} className="d-flex justify-content-between">
              <span>
                {!!country && <CountryFlag country={country} width={20} />} {title}
              </span>
              <div className="d-flex gap-4 align-items-center">
                <span className="small text-neutral-500 flex-shrink-0">
                  {dayjs(result.date).fromNow()}
                </span>
                <span className="small text-neutral-500 flex-shrink-0" style={{ width: '80px' }}>
                  <SearchActions item={result} />
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
